import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(_auth, router, loc) {
        this._auth = _auth;
        this.router = router;
        this.loc = loc;
    }
    AuthGuard.prototype.canActivate = function () {
        // if (!this._auth.isAuthenticated()) {
        //   this._auth.redirectUrl = this.loc.path();
        //   this.router.navigate(['login']);
        //   return false;
        // } else {
        // this._auth.getProfile()
        return true;
        // }
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
var ClientAuthGuard = /** @class */ (function () {
    function ClientAuthGuard(_auth, router, loc) {
        this._auth = _auth;
        this.router = router;
        this.loc = loc;
    }
    ClientAuthGuard.prototype.canActivate = function () {
        // if (!this._auth.isAuthenticated()) {
        //   this._auth.redirectUrl = this.loc.path();
        //   this.router.navigate(['login']);
        //   return false;
        // } else {
        // this._auth.getProfile()
        return true;
        // }
    };
    ClientAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClientAuthGuard_Factory() { return new ClientAuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location)); }, token: ClientAuthGuard, providedIn: "root" });
    return ClientAuthGuard;
}());
export { ClientAuthGuard };
var AdminAuthGuard = /** @class */ (function () {
    function AdminAuthGuard(_auth, router, loc) {
        this._auth = _auth;
        this.router = router;
        this.loc = loc;
    }
    AdminAuthGuard.prototype.canActivate = function () {
        // if (!this._auth.isAuthenticated() && !this._auth.isStaff()) {
        //   this._auth.redirectUrl = this.loc.path();
        //   this.router.navigate(['login']);
        //   return false;
        // } else {
        // //   this._auth.getProfile()
        // }
        return true;
    };
    AdminAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminAuthGuard_Factory() { return new AdminAuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location)); }, token: AdminAuthGuard, providedIn: "root" });
    return AdminAuthGuard;
}());
export { AdminAuthGuard };
var ClientLoadGuard = /** @class */ (function () {
    function ClientLoadGuard(_auth, router, loc) {
        this._auth = _auth;
        this.router = router;
        this.loc = loc;
    }
    ClientLoadGuard.prototype.canLoad = function () {
        if (this._auth.isAuthenticated()) {
            return true;
        }
        else {
            this._auth.redirectUrl = this.loc.path();
            this.router.navigate(['login']);
            return false;
        }
    };
    ClientLoadGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClientLoadGuard_Factory() { return new ClientLoadGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location)); }, token: ClientLoadGuard, providedIn: "root" });
    return ClientLoadGuard;
}());
export { ClientLoadGuard };
var AdminLoadGuard = /** @class */ (function () {
    function AdminLoadGuard(_auth, router, loc) {
        this._auth = _auth;
        this.router = router;
        this.loc = loc;
    }
    AdminLoadGuard.prototype.canLoad = function () {
        if (this._auth.isAuthenticated() && this._auth.isStaff()) {
            return true;
        }
        else {
            this._auth.redirectUrl = this.loc.path();
            this.router.navigate(['login']);
            return false;
        }
    };
    AdminLoadGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminLoadGuard_Factory() { return new AdminLoadGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location)); }, token: AdminLoadGuard, providedIn: "root" });
    return AdminLoadGuard;
}());
export { AdminLoadGuard };
