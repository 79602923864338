/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./buttons.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./buttons.component";
import * as i3 from "@angular/router";
import * as i4 from "../../services/assignments.service";
import * as i5 from "@angular/material/dialog";
var styles_ButtonsComponent = [i0.styles];
var RenderType_ButtonsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonsComponent, data: {} });
export { RenderType_ButtonsComponent as RenderType_ButtonsComponent };
export function View_ButtonsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "material-icons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["edit"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "material-icons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getPDF() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["picture_as_pdf"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "i", [["class", "material-icons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.view() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["pageview"]))], null, null); }
export function View_ButtonsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-buttons", [], null, null, null, View_ButtonsComponent_0, RenderType_ButtonsComponent)), i1.ɵdid(1, 49152, null, 0, i2.ButtonsComponent, [i3.Router, i4.AssignmentsService, i5.MatDialog], null, null)], null, null); }
var ButtonsComponentNgFactory = i1.ɵccf("app-buttons", i2.ButtonsComponent, View_ButtonsComponent_Host_0, {}, {}, []);
export { ButtonsComponentNgFactory as ButtonsComponentNgFactory };
