var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormtrackResponse } from "../../shared/models/formtrack.http";
import { User } from "../../shared/models/user";
import { OWNERTYPE_INDIVIDUAL_ID, STREETTYPE, UNITTYPE, AU_STATES_WITH_SHORTNAME } from "src/environments/constants";
var SwhModelResponse = /** @class */ (function (_super) {
    __extends(SwhModelResponse, _super);
    function SwhModelResponse(error, swhModel) {
        return _super.call(this, error, swhModel) || this;
    }
    return SwhModelResponse;
}(FormtrackResponse));
export { SwhModelResponse };
var SWHInstallersModelResponse = /** @class */ (function (_super) {
    __extends(SWHInstallersModelResponse, _super);
    function SWHInstallersModelResponse(error, swhInstallersModel) {
        return _super.call(this, error, swhInstallersModel) || this;
    }
    return SWHInstallersModelResponse;
}(FormtrackResponse));
export { SWHInstallersModelResponse };
var SWHAssignmentItem = /** @class */ (function () {
    function SWHAssignmentItem() {
        this.installerSerial = 0;
        this.linkToSerial = 0;
        this.linkFromSerial = 0;
        this.createdBy = 0;
        this.updatedBy = 0;
        this.status = 0;
        this.gstInvoiceSerial = 0;
        this.gstInvoiceFileName = '';
        this.tax1InvoiceSerial = 0;
        this.tax1InvoiceFileName = '';
        this.tax2InvoiceSerial = 0;
        this.tax2InvoiceFileName = '';
        this.recFormSerial = 0;
        this.recFormFileName = '';
        this.nominationFormSignedSerial = 0;
        this.nominationFormSignedFileName = '';
        //START Form Component - Photos and Documents
        this.originalUnitsTankLabelPhotoSerial = 0;
        this.originalUnitsTankLabelPhotoFileName = '';
        this.originalUnitBeforeDecommissioningPhotoSerial = 0;
        this.originalUnitBeforeDecommissioningPhotoFileName = '';
        this.originalUnitOnceRemovedDecommissionedPhotoSerial = 0;
        this.originalUnitOnceRemovedDecommissionedPhotoFileName = '';
        this.tankPanelSerialNumberSerial = 0;
        this.tankPanelSerialNumberFileName = '';
        this.newUnitInstalledPhotoSerial = 0;
        this.newUnitInstalledPhotoFileName = '';
        this.newUnitsTankLabelSerial = 0;
        this.newUnitsTankLabelFileName = '';
        this.EFTPOSBankReceiptSerial = 0;
        this.EFTPOSBankReceiptFileName = '';
        this.proofAddressSerial = 0;
        this.signatureSelfiePhotoSerial = 0;
        this.signatureSelfiePhotoFileName = '';
        this.plumbingCertificateCompliancePhotoSerial = 0;
        this.plumbingCertificateCompliancePhotoFileName = '';
        this.electricalCertificateCompliancePhotoSerial = 0;
        this.electricalCertificateCompliancePhotoFileName = '';
        this.invoiceFormSerial = 0;
        this.invoiceFormFileName = '';
        this.purchaseSerial = 0;
        this.purchaseFileName = '';
        this.additionalDocumentsSerial = 0;
        this.additionalDocumentsFileName = '';
        this.additionalDocuments2Serial = 0;
        this.additionalDocuments2FileName = '';
        this.additionalDocuments3Serial = 0;
        this.additionalDocuments3FileName = '';
        this.compressorPhotoSerial = 0;
        this.compressorLabelPhotoSerial = 0;
        this.recyclingReceiptPhotoSerial = 0;
        this.signatureSelfieMiddleSerial = 0;
        this.signatureSelfieMiddleFileName = '';
        this.signatureSelfieEndSerial = 0;
        this.signatureSelfieEndFileName = '';
        //END Form Component - Photos and Documents
        this.postImplementationFormSignedSerial = 0;
        this.postImplementationFormSignedFileName = '';
        this.electricityBillSerial = 0;
        this.electricityBillFileName = '';
        this.STCFormSerial = 0;
        this.STCFormFileName = '';
        this.ESCFormSerial = 0;
        this.ESCFormFileName = '';
        this.VEECFormSerial = 0;
        this.VEECFormFileName = '';
        this.siteAssessmentDeclarationFormSerial = 0;
        this.siteAssessmentDeclarationFileName = '';
        this.photoSerial = 0;
        this.housePhotoSerial = 0;
        this.electricalPhotoCertSerial = 0;
        this.complianceSerial = 0;
        this.ownerType = 0;
        this.ownerOrganisationName = '';
        this.ownerOrganisationType = '';
        this.ownerFirstName = '';
        this.ownerLastName = '';
        this.ownerUnitType = -1;
        this.ownerUnitNumber = '';
        this.ownerStreetNumber = '';
        this.ownerStreetName = '';
        this.ownerStreetType = 0;
        this.ownerSuburb = '';
        this.ownerPostcode = '';
        this.ownerState = 0;
        this.ownerStateName = '';
        this.ownerPhoneBH = '';
        this.ownerPhoneAH = '';
        this.ownerEmail = '';
        this.ownerLocation = '';
        this.installSameAsPostal = 0;
        this.installPropertyType = 0;
        this.installStories = 0;
        this.installUnitType = -1;
        this.installUnitNumber = '';
        this.installStreetNumber = '';
        this.installStreetName = '';
        this.installStreetType = 0;
        this.installSuburb = '';
        this.installPostcode = '';
        this.installState = 0;
        this.installLatitude = '';
        this.installLongitude = '';
        this.installLocation = '';
        this.installMultiple = 0;
        this.installAdditionalInformation = '';
        this.installPreviousFailed = 0;
        this.installPreviousFailedNotes = '';
        this.specialAddress = '';
        this.brand = '';
        this.model = '';
        this.modelType = -1;
        this.systemModelSerial = 0;
        this.numberPanels = 0;
        this.capacity = 0;
        this.serialNumber = '';
        this.isDuplicateTankSerial = false;
        this.installType = -1;
        this.personSerial = 0; //Installer PersonSerial, potentially 
        this.electricianID = 0; //Electrician PersonSerial Id
        this.installerDetail = new User();
        this.installerArray = []; //The installer collection for the assignment
        this.installerFirstName = '';
        this.installerLastName = '';
        this.installerUsername = '';
        this.installerStateName = '';
        this.installerUnitType = -1;
        this.installerUnitNumber = '';
        this.installerStreetNumber = '';
        this.installerStreetName = '';
        this.installerStreetType = 0;
        this.installerSuburb = '';
        this.installerState = 0;
        this.installerPhone = '';
        this.installerEmail = '';
        this.installerLicence = ''; //CEC Accreditation 'Accreditation Value'
        this.installerAccreditationType = '';
        this.installerPlumbingLicense = '';
        this.installerPlumbingAccreditation = '';
        this.installerPlumbingAccreditationType = '';
        this.electricianFirstName = '';
        this.electricianLastName = '';
        this.electricianUsername = '';
        this.electricianStateName = '';
        this.electricianUnitType = -1;
        this.electricianUnitNumber = '';
        this.electricianStreetNumber = '';
        this.electricianStreetName = '';
        this.electricianStreetType = 0;
        this.electricianSuburb = '';
        this.electricianState = 0;
        this.electricianPhone = '';
        this.electricianEmail = '';
        this.electricianLicence = '';
        this.paymentType = -1;
        this.paymentName = '';
        this.paymentEFT = -1;
        this.paymentBSB = '';
        this.paymentAccountName = '';
        this.paymentAccountNumber = '';
        this.paymentPersonalUse = -1;
        this.paymentBusinessName = '';
        this.paymentABN = '';
        this.paymentRECs = 0;
        this.paymentPrice = 0.00;
        this.readTermsAndConditions = 0;
        this.ownerSignedName = '';
        this.ownerSignatureSerial = 0;
        this.ownerSignedDigitally = 0;
        this.witnessSignedName = '';
        this.witnessSignatureSerial = 0;
        this.reductionType = -1;
        this.pricePerSTC = 0.00;
        this.priceFix = 0.00;
        this.includeGST = 0;
        this.priceFixIncGST = 0.00;
        this.priceSTCIncGST = 0.00;
        this.compliancePlateSerial = 0;
        this.inverterCompliancePlateSerial = 0;
        this.systemInstallOther = '';
        this.MYOBUpdatedDate = '';
        this.accountManager = 0;
        this.type = 0;
        this.proofAddressFileName = '';
        this.installStateName = '';
        this.editableAssignment = false;
        this.isStaff = false;
        this.paymentFormValid = false;
        this.addressFormValid = false;
        //VEEC/ESS
        this.claimingESCorVEEC = false;
        this.paymentESCorVEEC = 0;
        this.numberESCorVEEC = 0;
        this.buildingOlderThenTwoYears = true; //tbl Installation Information
        this.productMeetsSizingRecommendation = true; //tbl Installation Information
        this.volumetricCapacityGreaterThan700 = true; //tbl Installation Information
        this.isVBAComplianceCertificateNumberRequired = true; //tbl Installation Information
        this.isElectricalSafetyNumberRequired = true; //tbl Installation Information
        //Electricity changes
        this.isHEEREligible = false;
        this.electricityBill = '';
        this.electricityBillValue = 0;
        this.electricityBillMonthRange = 1;
        this.isExistingInstalledLonger = true;
        this.isElectricalWorkRequired = true;
        this.isPaperClaim = false; //Indicates if the assignment is a 'paper claim' submitted by staff
        this.paperClaimUploadName = '';
        //Replaced Unit Information
        this.replacedUnitTankSize = 0; //tbl systemSolar
        this.replacedUnitManufacturedLabelPhotoFileName = '';
        //VEEC Consumer Checklist values
        this.consumerReceivedVBAComplianceCertificate = true;
        this.consumerReceivedElectricalSafetyCertificate = true;
        this.customerInformedOfComplianceAndSafetyCertificates = true;
        this.consumerProvidedVEUFactSheet = true;
        this.consumerInformedOfInstalledProductSize = true;
        // Cold room fields
        this.isColdroomActivity = false;
        this.coldroomEligibilityCheck1 = false;
        this.coldroomEligibilityCheck2 = false;
        this.isColdRoomBelowZero = false;
        this.upgradeRefrigerantType = '';
        this.coldroomProducts = [];
        this.coldroomStoredProducts = '';
        this.coldroomInternalFloorSpecificationEvidenceSerial = 0;
        this.coldroomInternalFloorSpecificationEvidenceFileName = '';
    }
    Object.defineProperty(SWHAssignmentItem.prototype, "ownerFullName", {
        get: function () {
            if (this.ownerType == OWNERTYPE_INDIVIDUAL_ID) {
                return this.ownerFirstName + ' ' + this.ownerLastName;
            }
            return this.ownerOrganisationName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SWHAssignmentItem.prototype, "fullInstallationAddress", {
        get: function () {
            return this._installationAddress ? this._installationAddress : this.formatInstallationAddress();
        },
        enumerable: true,
        configurable: true
    });
    ;
    SWHAssignmentItem.prototype.deserialize = function (input) {
        Object.assign(this, input);
        console.log('SWHAssignmentItem', this);
        this.coldroomProducts = this.isColdroomActivity && input.coldroomProducts && !Array.isArray(input.coldroomProducts) ? JSON.parse(input.coldroomProducts) : [];
        this.installMultiple = Number(input.installMultiple);
        this.installPreviousFailed = Number(input.installPreviousFailed);
        this.claimingESCorVEEC = Boolean(input.claimingESCorVEEC);
        this.ownerType = Number(input.ownerType);
        this.installPropertyType = Number(input.installPropertyType);
        // VEEC legal questions
        this.consumerReceivedVBAComplianceCertificate = input.consumerReceivedVBAComplianceCertificate !== "0";
        this.consumerReceivedElectricalSafetyCertificate = input.consumerReceivedElectricalSafetyCertificate !== "0";
        this.customerInformedOfComplianceAndSafetyCertificates = input.customerInformedOfComplianceAndSafetyCertificates !== "0";
        this.consumerProvidedVEUFactSheet = input.consumerProvidedVEUFactSheet !== "0";
        this.consumerInformedOfInstalledProductSize = input.consumerInformedOfInstalledProductSize !== "0";
        return this;
    };
    SWHAssignmentItem.prototype.formatInstallationAddress = function () {
        var _this = this;
        var unit = '';
        var street = '';
        var suburb = '';
        var postcode = '';
        var stateAbbreviation = '';
        var unitType = '';
        this._installationAddress = '';
        if (this.installUnitType && 0 < this.installUnitType && this.installUnitType <= STREETTYPE.length) {
            unitType = UNITTYPE.filter(function (aUnitType) { return aUnitType.id == _this.installUnitType; })[0].name;
        }
        unit = unitType + ' ' + this.installUnitNumber;
        if (this.installStreetType && 0 < this.installStreetType && this.installStreetType <= STREETTYPE.length) {
            var streetType = STREETTYPE.filter(function (aStreetType) { return aStreetType.id == _this.installStreetType; })[0].name;
            street = this.installStreetNumber + ' ' + this.installStreetName + ' ' + streetType;
        }
        suburb = this.installSuburb;
        if (this.installState && this.installState > 0) {
            stateAbbreviation = AU_STATES_WITH_SHORTNAME.filter(function (aState) { return aState.id == _this.installState; })[0].abbreviation;
        }
        postcode = this.installPostcode;
        if (unit && unit.trim().length > 0) {
            this._installationAddress = unit + ', ';
        }
        if (street && street.trim().length > 0) {
            this._installationAddress += street + ', ';
        }
        if (suburb && suburb.trim().length > 0) {
            this._installationAddress += suburb + ', ';
        }
        if (stateAbbreviation && stateAbbreviation.trim().length > 0) {
            this._installationAddress += stateAbbreviation + ', ';
        }
        this._installationAddress = this._installationAddress += postcode;
        return this._installationAddress;
    };
    return SWHAssignmentItem;
}());
export { SWHAssignmentItem };
var additionalPhoto = /** @class */ (function () {
    function additionalPhoto() {
    }
    return additionalPhoto;
}());
export { additionalPhoto };
var BrandModel = /** @class */ (function () {
    function BrandModel() {
    }
    return BrandModel;
}());
export { BrandModel };
var SystemModel = /** @class */ (function () {
    function SystemModel() {
    }
    return SystemModel;
}());
export { SystemModel };
var SwhModel = /** @class */ (function () {
    function SwhModel() {
    }
    return SwhModel;
}());
export { SwhModel };
var SWHInstallersModel = /** @class */ (function () {
    function SWHInstallersModel() {
    }
    return SWHInstallersModel;
}());
export { SWHInstallersModel };
var InstallActivitiesModel = /** @class */ (function () {
    function InstallActivitiesModel() {
    }
    return InstallActivitiesModel;
}());
export { InstallActivitiesModel };
//When updating this list please update the pdf model in backend as well: swhVEEC_ColdroomAssignmentFormHtml
export var ColdroomProductType;
(function (ColdroomProductType) {
    ColdroomProductType[ColdroomProductType["ElectronicExpansionValve"] = 0] = "ElectronicExpansionValve";
    ColdroomProductType[ColdroomProductType["SuperheatController"] = 1] = "SuperheatController";
    ColdroomProductType[ColdroomProductType["Compressor"] = 2] = "Compressor";
    ColdroomProductType[ColdroomProductType["CondensingTechnology"] = 3] = "CondensingTechnology";
    ColdroomProductType[ColdroomProductType["ECVSDCondenserFan"] = 4] = "ECVSDCondenserFan";
    ColdroomProductType[ColdroomProductType["ECEvaporatorFan"] = 5] = "ECEvaporatorFan";
})(ColdroomProductType || (ColdroomProductType = {}));
