/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./readytosubmit-widget.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./readytosubmit-widget.component";
import * as i4 from "../../services/assignments.service";
var styles_ReadytosubmitWidgetComponent = [i0.styles];
var RenderType_ReadytosubmitWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReadytosubmitWidgetComponent, data: {} });
export { RenderType_ReadytosubmitWidgetComponent as RenderType_ReadytosubmitWidgetComponent };
function View_ReadytosubmitWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "material-icons grey"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["announcement"]))], null, null); }
function View_ReadytosubmitWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.statusName; _ck(_v, 1, 0, currVal_0); }); }
export function View_ReadytosubmitWidgetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], [[2, "ready-to-submit", null], [2, "text-cell", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], [[4, "color", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadytosubmitWidgetComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadytosubmitWidgetComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.value == 15); _ck(_v, 3, 0, currVal_3); var currVal_4 = (_co.value != 15); _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.statusName == _co.readyStatus); var currVal_1 = (_co.statusName != _co.readyStatus); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.statusColour; _ck(_v, 1, 0, currVal_2); }); }
export function View_ReadytosubmitWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-readytosubmit-widget", [], null, null, null, View_ReadytosubmitWidgetComponent_0, RenderType_ReadytosubmitWidgetComponent)), i1.ɵdid(1, 49152, null, 0, i3.ReadytosubmitWidgetComponent, [i4.AssignmentsService], null, null)], null, null); }
var ReadytosubmitWidgetComponentNgFactory = i1.ɵccf("app-readytosubmit-widget", i3.ReadytosubmitWidgetComponent, View_ReadytosubmitWidgetComponent_Host_0, {}, {}, []);
export { ReadytosubmitWidgetComponentNgFactory as ReadytosubmitWidgetComponentNgFactory };
