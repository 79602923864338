import { EventEmitter } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { map } from 'rxjs/operators';
import * as moment from "moment";
import * as ENV from 'src/environments/environment';
import * as myGlobals from 'src/environments/constants';
import { recPriceModel } from '../models/assignments-view';
import { ApiService } from '../../../services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { PVDAssignmentItem, SerialNumbersInfo } from '../../assignment-pvd/models/PVDAssignmentResponse';
import { ImportModel } from '../../shared/models/import.model';
import { SWHAssignmentItem } from '../../assignment-swh/models/SWHAssignmentResponse';
import { GridFilter } from '../../shared/models/filter';
import { ReadyToSubmitModel } from '../models/readytosubmitmodel';
import { ReadytosubmitDialogComponent } from '../components/readytosubmit-dialog/readytosubmit-dialog.component';
import { ConfirmationDialogModel } from '../../shared/models/confirmation-dialog.model';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/api.service";
import * as i2 from "../../../services/auth.service";
import * as i3 from "@angular/material/snack-bar";
import * as i4 from "@angular/material/dialog";
var AssignmentsService = /** @class */ (function () {
    function AssignmentsService(_api, _auth, sb, matDialog) {
        this._api = _api;
        this._auth = _auth;
        this.sb = sb;
        this.matDialog = matDialog;
        // The below variables are required to store VIEW and STATUS states of the grid
        this.VIEWS = myGlobals.VIEWS;
        this._view = myGlobals.VIEWS.ALL;
        this.STATUS = myGlobals.STATUS;
        this._status = myGlobals.STATUS.ALL;
        this.gridState = null;
        this.gridAllAssState = null;
        this.gridReportAssState = null;
        this.totalSubmittedRECs = 0;
        this.loaded = false;
        this.refreshAfterSave = false;
        this.ownerType = myGlobals.OwnerType;
        this.propertyType = myGlobals.PropertyType;
        this.pendingCSV = 0;
        this.checkingMB = 0;
        this.pendingMYOB = 0;
        this.recPrice = new recPriceModel();
        this.importCSV = new ImportModel();
        this.viewAssignment = 0;
        this.goToView = new EventEmitter();
        this.assignmentFetched = new EventEmitter();
        this.refreshView = new EventEmitter();
        this.loading = new EventEmitter();
        this.loadingGrid = new EventEmitter();
        this.viewTab = new EventEmitter();
        this.isStaff = false;
        this.searchInput = "";
        this.searchSerialInput = "";
        this.types = [
            { value: myGlobals.ASSIGNMENT_TYPE_PVD, label: myGlobals.PVD_NAME },
            { value: myGlobals.ASSIGNMENT_TYPE_SWH, label: myGlobals.SWH_NAME }
        ];
        this.typesAdapter = new jqx.dataAdapter(this.types, { autoBind: true });
        // private typesSource: any =
        //   {
        //     datatype: 'array',
        //     datafields: [
        //       { name: 'label', type: 'string' },
        //       { name: 'value', type: 'string' }
        //     ],
        //     localdata: this.types
        //   }
        this.ownerTypes = myGlobals.OWNERTYPE.filter(function (ownerType) { return ownerType.id != 0; })
            .map(function (ownerType) {
            return { value: ownerType.id, label: ownerType.name };
        });
        this.ownerTypeAdapter = new jqx.dataAdapter(this.ownerTypes, { autoBind: true });
        this.paymentStatus = myGlobals.PAID_STATUS.map(function (status) { return { value: status.id, label: status.name }; });
        this.paymentStatusAdapter = new jqx.dataAdapter(this.paymentStatus, { autoBind: true });
        this.registryStatus = myGlobals.REGISTRY_STATUS.filter(function (status) {
            return [0, 2, 4, 6].includes(status.id);
        }).map(function (status) {
            return { value: status.id, label: status.name };
        });
        this.registryStatusAdapter = new jqx.dataAdapter(this.registryStatus, { autoBind: true });
        this.myobStatus = myGlobals.MYOB_STATUS.map(function (status) { return { value: status.id, label: status.name }; });
        this.myobStatusAdapter = new jqx.dataAdapter(this.myobStatus, { autoBind: true });
        this.assignmentStatus = myGlobals.ASSIGNMENTS_STATUS.filter(function (status) {
            return status.id;
        }).map(function (status) {
            return { value: status.name, label: status.name };
        });
        this.clientAssignmentStatus = myGlobals.ASSIGNMENTS_STATUS.filter(function (status) {
            return [0, 12, 11, 13, 14, 15, 15].includes(status.id);
        }).map(function (status) {
            return { value: status.name, label: status.name };
        });
        this.assignmentStatusAdapter = new jqx.dataAdapter(this.assignmentStatus, { autoBind: true });
        this.source = {
            localdata: [],
            totalrecords: 100,
            complete: 0,
            checking: 0,
            pending: 0,
            all: 0,
            incomplete: 0,
            datafields: [
                { name: 'link', type: 'string' },
                { name: 'serial', type: 'string' },
                { name: 'createdDate', type: 'date', cellsformat: myGlobals.DATE_FORMAT_FILTER },
                { name: 'assignmentName', type: 'string', value: 'statusName', values: { source: this.assignmentStatusAdapter.records, value: 'value', name: 'label' } },
                { name: 'statusName', type: 'string' },
                { name: 'typeName', type: 'string', value: 'type', values: { source: this.typesAdapter.records, value: 'value', name: 'label' } },
                { name: 'type', type: 'string' },
                { name: 'paymentReceivedDate', type: 'date', cellsformat: myGlobals.DATE_FORMAT_FILTER },
                { name: 'ownerType', type: 'string', value: 'ownerType', values: { source: this.ownerTypeAdapter.records, value: 'value', name: 'label' } },
                { name: 'name', type: 'string' },
                { name: 'address', type: 'string' },
                { name: 'pricePerSTC', type: 'number' },
                { name: 'todayPrice', type: 'string' },
                { name: 'paymentGST', type: 'string' },
                { name: 'paymentTotal', type: 'double' },
                { name: 'paymentPrice', type: 'double' },
                { name: 'paymentRECs', type: 'number' },
                { name: 'escS', type: 'double' },
                { name: 'escPrice', type: 'double' },
                { name: 'veecS', type: 'double' },
                { name: 'veecPrice', type: 'double' },
                { name: 'paymentStatusName', type: 'string', value: 'paymentName', values: { source: this.paymentStatusAdapter.records, value: 'value', name: 'label' } },
                { name: 'paymentName', type: 'string' },
                { name: 'paidStatusName', type: 'string' },
                { name: 'paidStatusColour', type: 'string' },
                { name: 'paidDate', type: 'date', cellsformat: myGlobals.DATE_FORMAT_FILTER },
                { name: 'accreditationNumber', type: 'string' },
                { name: 'registryStatusName', type: 'string', value: 'registryStatus', values: { source: this.registryStatusAdapter.records, value: 'value', name: 'label' } },
                { name: 'registryStatus', type: 'string' },
                { name: 'installer', type: 'string' },
                { name: 'installDate', type: 'date', cellsformat: myGlobals.DATE_FORMAT_FILTER },
                { name: 'assignmentSerialDisplay', type: 'string' },
                { name: 'exportFileName', type: 'string' },
                { name: 'accountManagerPerson', type: 'string' },
                { name: 'MYOBUpdatedDate', type: 'date', cellsformat: myGlobals.DATE_FORMAT_FILTER },
                { name: 'myobStatus', type: 'string', value: 'myobStatus', values: { source: this.myobStatusAdapter.records, value: 'value', name: 'label' } },
                { name: 'myobStatusName', type: 'string' },
                { name: 'myobStatusColour', type: 'string' },
                { name: 'submittedByName', type: 'string' },
                { name: 'movedToCheckingByName', type: 'string' },
                { name: 'statusColour', type: 'string' },
                { name: 'registryStatusColour', type: 'string' },
                { name: 'fileName', type: 'string' },
                { name: 'totalRECs', type: 'string' },
                { name: 'fileSerial', type: 'number' },
                { name: 'createdDateFormatted', type: 'string' },
                { name: 'exportStatus', type: 'number' },
                { name: 'existClientCard', type: 'string' },
                { name: 'createdBy', type: 'string' },
                { name: 'isPaperClaim', type: 'boolean' },
            ],
            datatype: 'json'
        };
        this.filters = new GridFilter('');
        this.allAssignmentFilters = new GridFilter('');
        this.reportFilters = new GridFilter('');
        this.previousFilter = [];
        this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
    }
    AssignmentsService.prototype.getAssignmentView = function (serial, swh) {
        var _this = this;
        this.loading.emit(true);
        var func = swh ? 'getSwhAssignment' : 'getPvdAssignment';
        this._api.get('greenbank', 'assignment', func, { arguments: serial }).subscribe(function (res) {
            if (res != null) {
                if (swh) {
                    _this.assignmentView = new SWHAssignmentItem().deserialize(res);
                    _this.checkSwhSerialDuplicates();
                }
                else {
                    _this.assignmentView = new PVDAssignmentItem().deserialize(res);
                    _this.calcSerialNumbers();
                }
                _this.assignmentFetched.emit(true);
                _this.loading.emit(false);
            }
        });
    };
    AssignmentsService.prototype.calcSerialNumbers = function () {
        var _this = this;
        this.assignmentView.serialNumbersInfo = new Array();
        if (this.assignmentView.serialNumbers.length > 0) {
            this.assignmentView.serialNumbers.forEach(function (element) {
                var checkPvdSerialfilters = {
                    checkPVDSerial: element[0],
                    checkPVDAssigmentSerial: _this.assignmentView.assignmentSerial
                };
                _this.checkPvdSerialDuplicates(checkPvdSerialfilters).subscribe(function (res) {
                    var item = new SerialNumbersInfo();
                    item.serialNumber = element[0];
                    item.duplicate = false;
                    item.verified = element[1] === '0' ? false : true;
                    if (res && res.length > 0) {
                        item.duplicate = true;
                    }
                    _this.assignmentView.serialNumbersInfo.push(item);
                });
            });
        }
    };
    AssignmentsService.prototype.checkPvdSerialDuplicates = function (checkPvdSerialfilters) {
        return this._api.get('greenbank', 'assignment', 'checkPvdSerialDuplicates', checkPvdSerialfilters, 'arguments');
    };
    AssignmentsService.prototype.checkSwhSerialDuplicates = function () {
        var _this = this;
        var postParams = {
            AssignmentSerial: this.assignmentView.assignmentSerial,
            Serial: this.assignmentView.serialNumber
        };
        this._api.get('greenbank', 'assignment', 'checkSwhSerial', postParams).subscribe(function (res) {
            _this.assignmentView.isDuplicateTankSerial = false;
            if (res != null) {
                _this.assignmentView.isDuplicateTankSerial = true;
            }
        });
    };
    AssignmentsService.prototype.deleteAssignment = function (params, functionName) {
        return this._api.get('greenbank', 'assignment', functionName, params, 'arguments');
    };
    AssignmentsService.prototype.cancelAssignment = function (params, functionName) {
        return this._api.get('greenbank', 'assignment', functionName, params, 'arguments');
    };
    AssignmentsService.prototype.getPersonBySerial = function (params) {
        return this._api.get('contacts', 'person', 'getPersonBySerial', params, 'arguments');
    };
    AssignmentsService.prototype.getPvdHistoryList = function (params, isPvd) {
        var functionName = 'getSwhHistoryList';
        if (isPvd) {
            functionName = 'getPvdHistoryList';
        }
        return this._api.get('greenbank', 'history', functionName, params, 'arguments');
    };
    AssignmentsService.prototype.getNotes = function (params) {
        return this._api.get('greenbank', 'note', 'getNotes', params, 'arguments');
    };
    AssignmentsService.prototype.saveNote = function (params) {
        return this._api.get('greenbank', 'note', 'saveNote', params, 'arguments');
    };
    AssignmentsService.prototype.deleteNote = function (params) {
        return this._api.get('greenbank', 'note', 'deleteNoteBySerial', params, 'arguments');
    };
    AssignmentsService.prototype.getPagedAssignments = function (filters) {
        var _this = this;
        this.loadingGrid.emit(true);
        filters.take = filters.pageSize;
        filters.skip = (filters.page - 1) * filters.pageSize;
        return this._api.get('greenbank', 'assignment', 'getPagedAssignments', filters, 'filters').pipe(map(function (res) {
            if (res != null && res.assignments != null) {
                res.assignments.map(function (i) {
                    var type = i.type == 0 ? 'swh' : 'pvd';
                    i.link = type + "/" + i.serial.toString();
                    if (i.paymentReceivedDate == '0000-00-00')
                        i.paymentReceivedDate = '';
                    if (i.paidDate == '0000-00-00')
                        i.paidDate = '';
                    if (i.installDate == '0000-00-00')
                        i.installDate = '';
                    if (i.MYOBUpdatedDate == '0000-00-00')
                        i.MYOBUpdatedDate = '';
                    i.address = i.address && i.address !== '' ? i.address.replace(/\"/g, "") : '';
                    var assStatus = myGlobals.ASSIGNMENTS_STATUS.find(function (status) { return status.id == i.status; });
                    i.statusName = assStatus ? assStatus.name : '';
                    i.statusColour = assStatus ? assStatus.colour : '';
                    var paidStatus = myGlobals.PAID_STATUS.find(function (status) { return status.id == i.paidStatus; });
                    i.paidStatusName = paidStatus ? paidStatus.name : '';
                    i.paidStatusColour = paidStatus ? paidStatus.colour : '';
                    var registryStatus = myGlobals.ASSIGNMENTS_STATUS.find(function (status) { return status.id == i.registryStatus; });
                    i.registryStatusName = registryStatus ? registryStatus.name : '';
                    i.registryStatusColour = registryStatus ? registryStatus.colour : '';
                    var myobStatus = myGlobals.MYOB_STATUS.find(function (status) { return status.id == i.myobStatus; });
                    i.myobStatusName = myobStatus ? myobStatus.name : '';
                    i.myobStatusColour = myobStatus ? myobStatus.colour : '';
                });
            }
            else {
                _this.loadingGrid.emit(false);
            }
            _this.source.localdata = res.assignments;
            _this.source.totalrecords = res.totalRows;
            _this.dataAdapter.dataBind();
            _this.loadingGrid.emit(false);
        }));
    };
    AssignmentsService.prototype.getPagedAssignmentTotals = function (filters) {
        var _this = this;
        return this._api.get('greenbank', 'assignment', 'getPagedAssignmentTotals', filters, 'filters').pipe(map(function (res) {
            if (res != null) {
                Object.assign(_this.source, res);
            }
        }));
    };
    AssignmentsService.prototype.getFiles = function (filters) {
        var _this = this;
        this.loadingGrid.emit(true);
        return this._api.get('greenbank', 'file', 'getPagedFileList', filters, 'filters').pipe(map(function (res) {
            if (res != null) {
                _this.source.localdata = res.data;
                _this.source.totalrecords = res.data.length;
                _this.dataAdapter.dataBind();
                _this.loadingGrid.emit(false);
            }
        }));
    };
    AssignmentsService.prototype.getTodayRecPrice = function () {
        var _this = this;
        this._api.get('greenbank', 'setting', 'getTodayRecPrice', null).subscribe(function (res) {
            if (res != null) {
                _this.recPrice = res;
            }
        });
    };
    AssignmentsService.prototype.getTotalCompanySubmittedRECs = function (params) {
        var _this = this;
        var postParams = {};
        if (!params) {
            postParams = { groupSerial: this._auth.company.groupSerial, dealStartDate: this._auth.company.dealStartDate, dealEndDate: this._auth.company.dealEndDate };
        }
        else {
            postParams = params;
        }
        this._api.get('greenbank', 'assignment', 'getTotalCompanySubmittedRECs', postParams).subscribe(function (res) {
            if (res != null) {
                _this.totalSubmittedRECs = res;
            }
        });
    };
    AssignmentsService.prototype.getImportRec = function () {
        var _this = this;
        var params = { componentName: 'importexport', sectionSerialCSV: 5, profileTypeCSV: 1, asArrayCSV: 1, };
        this._api.get('greenbank', 'import', 'getProfilesBySectionSerialAndProfileType', params, 'arguments').subscribe(function (res) {
            if (res != null) {
                _this.importCSV = res;
            }
        });
    };
    AssignmentsService.prototype.getExportFields = function (assignmentType) {
        return this._api.get('greenbank', 'assignment', 'getExportFieldsAssignments', { assingType: assignmentType }, 'arguments');
    };
    AssignmentsService.prototype.getNumberChecking = function (filters) {
        var _this = this;
        this._api.get('greenbank', 'assignment', 'getPagedAssignmentTotals', filters, 'filters').subscribe(function (res) {
            if (res) {
                _this.checkingMB = res.checking;
            }
        });
    };
    AssignmentsService.prototype.getNumberCSV = function (filters) {
        var _this = this;
        this._api.get('greenbank', 'assignment', 'getPagedAssignmentTotals', filters, 'filters').subscribe(function (res) {
            if (res) {
                _this.pendingCSV = res.all;
            }
        });
    };
    AssignmentsService.prototype.getNumberMYOB = function (filters) {
        var _this = this;
        this._api.get('greenbank', 'assignment', 'getPagedAssignments', filters, 'filters')
            .subscribe(function (res) {
            if (res) {
                _this.pendingMYOB = res.assignments.length;
            }
        });
    };
    AssignmentsService.prototype.printAssignment = function (filters, type) {
        var method = type == 'swh' ? 'printSWHAssignment' : 'printPVDAssignment';
        this._api.get('greenbank', 'assignment', method, filters, 'arguments').subscribe(function (res) {
            if (res != null) {
                var session = sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId');
                var externalUrl = ENV.FORMTRACK_FILE_URL_STREAM + res.toString() + '?PHPSESSID=' + session;
                window.open(externalUrl, '_blank');
            }
        });
    };
    AssignmentsService.prototype.exportAssignmentCsv = function (filters) {
        this._api.get('greenbank', 'assignment', 'exportAssignmentCsv', filters, 'arguments').subscribe(function (res) {
            if (res != null) {
                var session = sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId');
                window.open(ENV.FORMTRACK_FILE_URL_STREAM + res.toString() + '?PHPSESSID=' + session);
            }
        });
    };
    AssignmentsService.prototype.verifyAssignment = function (filters, method) {
        return this._api.get('greenbank', 'assignment', method, filters, 'arguments').pipe(map(function (res) {
            if (res != null) {
                var session = sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId');
                window.open(ENV.FORMTRACK_FILE_URL_DOWNLOAD + res.toString() + '?PHPSESSID=' + session);
            }
        }));
    };
    //api call to create a csv file, the serial is then returned. 
    //The method named must correspond with its function name in the backend. 
    AssignmentsService.prototype.exportToCSV = function (param, method) {
        this._api.get('greenbank', 'assignment', method, param, 'arguments').subscribe(function (res) {
            if (res != null) {
                var session = sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId');
                window.open(ENV.FORMTRACK_FILE_URL_DOWNLOAD + res.toString() + '?PHPSESSID=' + session);
            }
        }, function (err) {
            console.error("ASSIGNMENTSERVICE - An Error has ocurred in exportToCSV() calling: " + method + " Resulting error: " + err);
        });
    };
    AssignmentsService.prototype.getCheckingAssignmentsPrintFile = function (filters) {
        this._api.get('greenbank', 'assignment', 'getCheckingAssignmentsPrintFile', filters, 'arguments').subscribe(function (res) {
            if (res != null) {
                var session = sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId');
                window.open(ENV.FORMTRACK_FILE_URL_DOWNLOAD + res.toString() + '?PHPSESSID=' + session);
            }
        });
    };
    AssignmentsService.prototype.exportMyobClientsCsv = function (filters, itemSelected) {
        var _this = this;
        this._api.get('greenbank', 'assignment', 'exportMyobClientsCsv', filters, 'arguments').subscribe(function (res) {
            if (res != null) {
                if (res !== Number) {
                    //TODO: The error returned is just a string from the backend this should be a catch instead of this garbage I wrote - LD. 
                    console.log(res);
                    throw res;
                }
                var session = sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId');
                window.open(ENV.FORMTRACK_FILE_URL_DOWNLOAD + res.toString() + '?PHPSESSID=' + session);
                itemSelected.forEach(function (element) {
                    _this.dataAdapter['records'][element].existClientCard = true;
                });
            }
        }, function (err) {
            console.error("ASSIGNMENTSERVICE - An Error has ocurred in exportMyobClientsCsv(): " + err);
        });
    };
    AssignmentsService.prototype.exportMyobPurchaseOrders = function (filters) {
        this._api.get('greenbank', 'assignment', 'exportMyobOrdersCsv', filters, 'arguments').subscribe(function (res) {
            if (res != null) {
                var session = sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId');
                window.open(ENV.FORMTRACK_FILE_URL_DOWNLOAD + res.toString() + '?PHPSESSID=' + session);
            }
        });
    };
    AssignmentsService.prototype.getAccountManager = function () {
        var _this = this;
        this._api.get('greenbank', 'setting', 'getAllStaffForAutoComplete', null).subscribe(function (res) {
            if (res != null) {
                _this.accountManagerSource = res;
            }
        });
    };
    AssignmentsService.prototype.getPVDAssignment = function (id) {
        return this._api.get('greenbank', 'assignment', 'getPvdAssignment', { id: id });
    };
    AssignmentsService.prototype.getSWHAssignment = function (id) {
        return this._api.get('greenbank', 'assignment', 'getSwhAssignment', { id: id });
    };
    AssignmentsService.prototype.getEmailDetail = function (params) {
        return this._api.get('greenbank', 'assignment', 'getEmailSignatureTemplate', params, 'arguments');
    };
    AssignmentsService.prototype.requestForInformation = function (params) {
        return this._api.get('greenbank', 'assignment', 'requestForInformation', params, 'arguments');
    };
    AssignmentsService.prototype.checkDigitalSignatureRequested = function (params) {
        return this._api.get('greenbank', 'assignment', 'checkDigitalSignatureRequested', params, 'arguments');
    };
    AssignmentsService.prototype.submitAssignment = function (filters, type) {
        var _this = this;
        var method = type == 'swh' ? 'submitSwhAssignment' : 'submitPvdAssignment';
        console.log('filters:', filters);
        this._api.get('greenbank', 'assignment', method, filters, 'arguments').subscribe(function (res) {
            if (res != null && res['Error']) {
                var dialogTitle = 'Already Submitted';
                var infoDialog = new ConfirmationDialogModel(dialogTitle, res['Error'], '', 'Ok');
                _this.matDialog.open(ConfirmationDialogComponent, {
                    data: infoDialog
                });
            }
        });
    };
    AssignmentsService.prototype.readyToSubmit = function (assignmentSerial, type) {
        var _this = this;
        if (type == myGlobals.ASSIGNMENT_TYPE_PVD) {
            this.getPVDAssignment(assignmentSerial).subscribe(function (res) {
                var assignment = res;
                var readyToSubmitModel = _this.calReadyToSubmit(assignment);
                _this.openReadyToSubmitDialog(readyToSubmitModel);
            });
        }
        else {
            this.getSWHAssignment(assignmentSerial).subscribe(function (res) {
                var assignment = res;
                var readyToSubmitModel = _this.calReadyToSubmit(assignment);
                _this.openReadyToSubmitDialog(readyToSubmitModel);
            });
        }
    };
    AssignmentsService.prototype.openReadyToSubmitDialog = function (readyToSubmitModel) {
        var _this = this;
        var dialogRef = this.matDialog.open(ReadytosubmitDialogComponent, {
            data: readyToSubmitModel
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.submitAssignment({ assignmentSerialSubmit: readyToSubmitModel.assignmentSerial, dealRatePrice: readyToSubmitModel.paymentPrice }, readyToSubmitModel.assignmentType == myGlobals.ASSIGNMENT_TYPE_PVD ? 'pvd' : 'swh');
                _this.refreshView.emit(true);
            }
        });
    };
    AssignmentsService.prototype.calReadyToSubmit = function (assignment) {
        var owner = '';
        if (assignment.ownerType == myGlobals.OWNERTYPE_INDIVIDUAL_ID)
            owner = assignment.ownerFirstName + ' ' + assignment.ownerLastName;
        else
            owner = assignment.ownerOrganisationName;
        var installerAddress = this.getInstallerAddress(assignment);
        var serialNumbers = assignment.serialNumbers;
        var pricePerSTC = assignment.paymentPrice > 0 ? '$' + assignment.paymentPrice : 'TBD';
        var dealPrice = 0.00;
        // Deal Rate implementation
        if (this._auth.company.dealRateCustomer == 1 && this.isDealValid()) {
            if (this._auth.company.volumeType == 1 || (assignment.paymentRECs >= this._auth.company.volumeMinimum && this.totalSubmittedRECs <= this._auth.company.volumeMaximum)) {
                if (this._auth.company.dealType == 1) {
                    dealPrice = Number(this._auth.company.dealTypeValue);
                }
                else {
                    dealPrice = Number(this._auth.company.dealTypeValue) + Number(this.recPrice.formtrackStcNon);
                }
                pricePerSTC = '$' + dealPrice.toFixed(2);
                assignment.paymentPrice = dealPrice.toFixed(2);
            }
        }
        var gst;
        var total;
        var totalWithoutGST = assignment.paymentPrice * assignment.paymentRECs;
        if (assignment.paymentPersonalUse == 0) {
            gst = '$' + (totalWithoutGST * 0.1).toFixed(2);
            var totalWithGST = (totalWithoutGST * 1.1).toFixed(2);
            total = '$' + totalWithoutGST + ' (' + totalWithGST + ' inc. GST)';
        }
        else {
            gst = 'N/A';
            total = '$' + totalWithoutGST;
        }
        return new ReadyToSubmitModel(owner, installerAddress, serialNumbers, assignment.paymentRECs, Number(assignment.paymentPrice), pricePerSTC, gst, total, assignment.type, assignment.assignmentSerial, this.totalSubmittedRECs);
    };
    AssignmentsService.prototype.getInstallerAddress = function (assignment) {
        var propertyName = '';
        var unitNumber = '';
        var unitType = '';
        var streetNumber = '';
        var streetName = '';
        var streetType = '';
        var completeAddress = '';
        var address = '';
        if (assignment.installSameAsPostal) {
            if (assignment.ownerUnitType > -1) {
                unitType = myGlobals.UNITTYPE.find(function (x) { return x.id == assignment.ownerUnitType; }) ? myGlobals.UNITTYPE.find(function (x) { return x.id == assignment.ownerUnitType; }).name : '';
            }
            unitNumber = assignment.ownerUnitNumber;
            streetNumber = assignment.ownerStreetNumber;
            streetName = assignment.ownerStreetName;
            if (assignment.ownerStreetType > -1) {
                streetType = myGlobals.STREETTYPE.find(function (x) { return x.id == assignment.ownerStreetType; }) ? myGlobals.STREETTYPE.find(function (x) { return x.id == assignment.ownerStreetType; }).name : '';
            }
            completeAddress = assignment.ownerSuburb + ', ' + assignment.ownerStateName + ', ' + assignment.ownerPostcode;
        }
        else {
            propertyName = assignment.installPropertyName;
            if (assignment.ownerUnitType > -1) {
                unitType = myGlobals.UNITTYPE.find(function (x) { return x.id == assignment.installUnitType; }) ? myGlobals.UNITTYPE.find(function (x) { return x.id == assignment.installUnitType; }).name : '';
            }
            unitNumber = assignment.installUnitNumber;
            streetNumber = assignment.installStreetNumber;
            streetName = assignment.installStreetName;
            if (assignment.installStreetType > -1) {
                streetType = myGlobals.STREETTYPE.find(function (x) { return x.id == assignment.installStreetType; }) ? myGlobals.STREETTYPE.find(function (x) { return x.id == assignment.installStreetType; }).name : '';
            }
            completeAddress = assignment.installSuburb + ', ' + assignment.installStateName + ', ' + assignment.installPostcode;
        }
        address += propertyName ? propertyName + ', ' : '';
        address += unitType ? unitType + ' ' + unitNumber + ', ' : '';
        address += streetNumber + ' ' + streetName + ' ' + streetType + ', ' + completeAddress;
        return address;
    };
    AssignmentsService.prototype.isDealValid = function () {
        if (moment(this._auth.company.dealEndDate).isValid()) {
            return moment(this._auth.company.dealEndDate).isSameOrAfter(moment());
        }
        return false;
    };
    AssignmentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AssignmentsService_Factory() { return new AssignmentsService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.MatSnackBar), i0.ɵɵinject(i4.MatDialog)); }, token: AssignmentsService, providedIn: "root" });
    return AssignmentsService;
}());
export { AssignmentsService };
var GetType = /** @class */ (function () {
    function GetType(x) {
        this.TName = x.name;
    }
    return GetType;
}());
