var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FormtrackError = /** @class */ (function (_super) {
    __extends(FormtrackError, _super);
    function FormtrackError(errorCode, message, details, data) {
        var _this = _super.call(this, message) || this;
        _this.errorCode = errorCode;
        _this.details = details;
        _this.data = data;
        return _this;
    }
    return FormtrackError;
}(Error));
export { FormtrackError };
var FormtrackResponse = /** @class */ (function () {
    function FormtrackResponse(error, result) {
        this.error = error;
        this.result = result;
    }
    return FormtrackResponse;
}());
export { FormtrackResponse };
var SessionIdResult = /** @class */ (function () {
    function SessionIdResult() {
        this.sessionId = '';
        this.personSerial = 0;
    }
    return SessionIdResult;
}());
export { SessionIdResult };
