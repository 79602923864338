<div class="dialog-header">
  <div class="dialog-title">
    Greenbank Environmental: Installer/Representative
    <br /><br />
    Registration Form for ESS
  </div>
  <br />

</div>
<div class="dialog-body">
  <form class="induction-form" style="width: 50%" [formGroup]="angForm" (ngSubmit)="onSubmit()">
    <div class="field-groups-section">
      <h3>1. Primary Details</h3>
      <div class="field-group">
        <div class="field-name">1.1 Name</div>
        <input class="generic-input input-text-long partnerFirstName form-control" type="text" name="firstName"
          formControlName="firstName" required placeholder="First name">
        <input class="generic-input input-text-long partnerLastName form-control" type="text" name="lastName"
          formControlName="lastName" required placeholder="Last name">
      </div>

      <div class="field-group">
        <div class="field-name">1.2 Contact Details</div>
        <input class="generic-input input-text-long partnerContactDetails" type="text" name="mobileNumber"
          formControlName="mobileNumber" required placeholder="Mobile no">
      </div>

      <div class="field-group">
        <div class="field-name">1.2 Email</div>
        <input class="generic-input input-text-long partnerContactDetails" type="email" name="email"
          formControlName="email" required placeholder="Email">
      </div>
    </div>

    <div class="field-group-section">
      <div class="field-group">
        <h3>2. What is your role in relation to carrying out ESS activities?</h3>
        <mat-form-field class="essActivityRole-select input-select" appearance="outline">
          <mat-select formControlName="essActivityRole" (selectionChange)="onESSActivityRoleChange($event.value)"
            required>
            <mat-option *ngFor="let option of essActivitiesRoles" [value]="option">
              {{option}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!--This section becomes mandatory if the question 2 answer is 'Qualified installer'-->
    <div class="field-groups-section">
      <h3> 3. Licenses and Documents </h3>
      <h3 class="license-error" [hidden]="isLicenseRequired()"> *At least one valid License must be provided for new Qualified Installers*</h3>
      <!--Dynamic Licenses and Documents Group-->
      <div class="field-group">
        <button mat-raised-button type="button" (click)="addRow()"
          style="padding: 0px !important; height: 33px !important; margin-bottom: 10px">
          <mat-icon>add</mat-icon>
          Add
        </button>
        <table>
          <thead>
            <tr>
              <th>License Type:</th>
              <th>Identification Number</th>
              <th>Licence Expiry Date</th>
              <th>Upload Copy of Licence</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dynamic of dynamicArray; let i = index;" class="dynamic-array-table">
              <td>
                <mat-form-field class="input-select" appearance="outline">
                  <mat-select (selectionChange)="getVal($event.value, i, 'qualification')">
                    <mat-option *ngFor="let option of licenseTypes" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <input class="generic-input input-text-long licenseType-box" type="text"
                  (keyup)="getVal($event.target.value, i, 'verificationID')">
              </td>
              <td>
                <input class="generic-input input-text-long licenseType-box" type="date"
                  (change)="getVal($event.target.value, i, 'expiryDate')">
              </td>
              <td>
                <div class="uploadCopyOfLicence-box-ext">
                  <div class="uploadCopyOfLicence-box-int">
                    <input class="upload-inputBox" type="file" [multiple]="(maxFiles > 1)"
                      (change)="getVal($event, i, 'copyOfLicense')" accept=".jpg, .jpeg, .png, .pdf" />
                  </div>
                </div>
              </td>
              <td>
                <button type="button" mat-raised-button color="warn" id="deleteBtn" (click)="deleteRow(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Upload a photo id document to the system -->
      <div class="formGroup" [class.error-border]="isPhotoIdRequired()">
        <h4 class="photoIdTitle">Photo Id (Driver's License, Keypass, etc)</h4>
        <div class="form-file-imageBox-information">
          <div draggable="true" ngClass="{{dragAreaClass}}">
            <div class="row">
              <div class="col-md-12 text-center" id="partnerInductionPhotoId">
                <a href="javascript:void(0)" (click)="photoIdfile.click()">
                  Click to browse
                </a>
                Or Drag & Drop to upload your files
                <input class="upload-inputBox" type="file" #photoIdfile [multiple]="(maxFiles > 1)"
                  (change)="onPhotoIdFileUpload($event)" />
              </div>
            </div>
          </div>
          <div class="currentUploadedFile">
            <div *ngIf="partnerInductionPhotoId_Name.length > 0">Current File: {{partnerInductionPhotoId_Name}}</div>
          </div>
        </div>
      </div>

      <!--Upload Certificate -->
      <div class="disconnect-reconnect-accreditation-area">
        <div class="accreditation-textbox">
          <h4 class="accreditationTitle">Disconnect/Reconnect Accreditation Number (if applicable)</h4>
          <input class="generic-input input-text-long accreditation-textbox form-control" type="text" name="partnerInductionConnectionAccreditationNumber"
          formControlName="partnerInductionConnectionAccreditationNumber" placeholder="" maxlength="30">
        </div>
        <div class="accreditation-app-upload-file">
          <h4 class="accreditationTitle">Upload Copy of Certificate</h4>
          <div class="form-file-imageBox-information">
            <div draggable="true" ngClass="{{dragAreaClass}}" id="partnerInductionConnectionAccreditationCertificate">
              <div class="row">
                <div class="col-md-12 text-center">
                  <a href="javascript:void(0)" (click)="accreditationfile.click()">
                    Click to browse
                  </a>
                  Or Drag & Drop to upload your files
                  <input class="upload-inputBox" type="file" #accreditationfile [multiple]="(maxFiles > 1)"
                    (change)="onAccreditationFileUpload($event)" />
                </div>
              </div>
            </div>
            <div class="currentUploadedFile">
              <div *ngIf="partnerInductionConnectionAccreditationCertificate_Name.length > 0">Current File:
                {{partnerInductionConnectionAccreditationCertificate_Name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="field-groups-section">
      <h3> 4. Organization Name (if subcontracting, please specify name of company you are subcontracting for)</h3>
      <div class="field-group">
        <input class="generic-input input-text-long organizationName" type="text" name="organizationName"
          formControlName="organizationName" required placeholder="">
      </div>
    </div>

    <div class="field-groups-section">
      <button mat-raised-button color="primary" [disabled]="angForm.pristine || angForm.invalid" style="width: 100%">
        Join us
      </button>
    </div>
  </form>
</div>