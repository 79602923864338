import Bugsnag from '@bugsnag/js';
// import BugsnagPerformance from '@bugsnag/browser-performance';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { BUGSNAG_API_KEY } from '../environments/environment';
Bugsnag.start({ apiKey: BUGSNAG_API_KEY });
// BugsnagPerformance.start({ apiKey: '55765620bf7b51673133674632f218ca' })
// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
    return new BugsnagErrorHandler();
}
var ɵ0 = { disabled: true };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
