// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
    production: true
};

// Bugsnag API Key
export const BUGSNAG_API_KEY: string = '55765620bf7b51673133674632f218ca';

export const SITE_URL: string = 'https://formtrack-api.green-bank.com.au';
export const FORMTRACK_URL: string = 'https://formtrack-api.green-bank.com.au/?jsonRequest';
export const FT_POST: string = 'https://formtrack-api.green-bank.com.au/';

export const FORMTRACK_FILE_URL: string = 'https://formtrack-api.green-bank.com.au/greenbank/file/';
export const FORMTRACK_FILE_URL_DOWNLOAD: string = 'https://formtrack-api.green-bank.com.au/greenbank/file/download/';
export const FORMTRACK_FILE_URL_STREAM: string = 'https://formtrack-api.green-bank.com.au/greenbank/file/stream/';
export const FORMTRACK_CONTACT_FILE_URL_DOWNLOAD: string = 'https://formtrack-api.green-bank.com.au/contacts/file/download/';
export const FORMTRACK_FILE_ZIP_URL_DOWNLOAD: string = 'https://formtrack-api.green-bank.com.au/greenbank/file/zipDownload/';

// export const SITE_URL: string = 'https://test-formtrack-api.green-bank.com.au';
// export const FORMTRACK_URL: string = 'https://test-formtrack-api.green-bank.com.au/?jsonRequest';
// export const FT_POST: string = 'https://test-formtrack-api.green-bank.com.au/';

// export const FORMTRACK_FILE_URL: string = 'https://test-formtrack-api.green-bank.com.au/greenbank/file/';
// export const FORMTRACK_FILE_URL_DOWNLOAD: string = 'https://test-formtrack-api.green-bank.com.au/greenbank/file/download/';
// export const FORMTRACK_FILE_URL_STREAM: string = 'https://test-formtrack-api.green-bank.com.au/greenbank/file/stream/';
// export const FORMTRACK_CONTACT_FILE_URL_DOWNLOAD: string = 'https://test-formtrack-api.green-bank.com.au/contacts/file/download/';
// export const FORMTRACK_FILE_ZIP_URL_DOWNLOAD: string = 'https://test-formtrack-api.green-bank.com.au/greenbank/file/zipDownload/';


// export const SITE_URL: string = 'http://13.236.44.191:8080';
// export const FORMTRACK_URL: string = 'http://13.236.44.191:8080/?jsonRequest';
// export const FT_POST: string = 'http://13.236.44.191:8080/';
//
// export const FORMTRACK_FILE_URL: string = 'http://13.236.44.191:8080/greenbank/file/';
// export const FORMTRACK_FILE_URL_DOWNLOAD: string = 'http://13.236.44.191:8080/greenbank/file/download/';
// export const FORMTRACK_FILE_URL_STREAM: string = 'http://13.236.44.191:8080/greenbank/file/stream/';



