<div class="dialog-header">
  <div class="dialog-title">Submit Assignment: {{summary.owner}}</div>
  <button mat-flat-button type="button" class="grey close" (click)='closeDialog()'>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="dialog-body">
  <h3>You are saving the form for:</h3>
  <div>
    <label>Owner Name</label> {{summary.owner}}
  </div>
  <div>
    <label>Installation Address</label>{{summary.installationAddress}}
  </div>
  <div>
    <label>Serial Numbers</label> {{summary.serialNumber}}
  </div>
  <div>
    <label>Number of STCs</label> {{summary.numberSTC}}
  </div>
  <div>
    <label>Total Submitted RECs</label> {{summary.totalSubmittedRECs}}
  </div>
  <div>
    <label>Price per STCs</label> {{summary.pricePerSTC}}
  </div>
  <div>
    <label>GST</label> {{summary.GST}}
  </div>
  <div>
    <label>Total</label> {{summary.total}}
  </div>
  <div>
    Please ensure all fields are completed, and appropriate documentation is attached prior to submitting.
  </div>
  <div>
    Once an application has been submitted it cannot be reversed, and will be processed by Greenbank according to its
    Terms &amp; Conditions.
  </div>
  <div>
    Forms containing incomplete or incorrect information will be rejected by Greenbank and require resubmission.
  </div>

  <div>Please ensure all fields are completed and correct, and that appropriate documentation is attached prior to
    saving.</div>
</div>
<div class="dialog-footer">
  <button style="float:left" mat-raised-button color="primary" type="submit" [mat-dialog-close]="true">Submit</button>
  <button style="float:right" mat-raised-button color="accent" type="submit" [mat-dialog-close]="false">Close</button>
</div>