var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormtrackResponse } from '../../shared/models/formtrack.http';
import { ASSIGNMENT_TYPE_PVD, OWNERTYPE_INDIVIDUAL_ID, STREETTYPE, UNITTYPE, AU_STATES_WITH_SHORTNAME } from 'src/environments/constants';
import { User } from '../../shared/models/user';
var AddressModelResponse = /** @class */ (function (_super) {
    __extends(AddressModelResponse, _super);
    function AddressModelResponse(error, addressModel) {
        return _super.call(this, error, addressModel) || this;
    }
    return AddressModelResponse;
}(FormtrackResponse));
export { AddressModelResponse };
var InstallerModelResponse = /** @class */ (function (_super) {
    __extends(InstallerModelResponse, _super);
    function InstallerModelResponse(error, installerModel) {
        return _super.call(this, error, installerModel) || this;
    }
    return InstallerModelResponse;
}(FormtrackResponse));
export { InstallerModelResponse };
var ElectricianModelResponse = /** @class */ (function (_super) {
    __extends(ElectricianModelResponse, _super);
    function ElectricianModelResponse(error, elctricianModel) {
        return _super.call(this, error, elctricianModel) || this;
    }
    return ElectricianModelResponse;
}(FormtrackResponse));
export { ElectricianModelResponse };
var AccountManagerResponse = /** @class */ (function (_super) {
    __extends(AccountManagerResponse, _super);
    function AccountManagerResponse(error, accountManagerModel) {
        return _super.call(this, error, accountManagerModel) || this;
    }
    return AccountManagerResponse;
}(FormtrackResponse));
export { AccountManagerResponse };
var SettingsResponse = /** @class */ (function (_super) {
    __extends(SettingsResponse, _super);
    function SettingsResponse(error, settingsModel) {
        return _super.call(this, error, settingsModel) || this;
    }
    return SettingsResponse;
}(FormtrackResponse));
export { SettingsResponse };
var PVDAssignmentItem = /** @class */ (function () {
    function PVDAssignmentItem(status, item) {
        if (status === void 0) { status = 0; }
        this.installType = -1;
        this.ownerType = 0;
        this.personSerial = 0;
        this.linkToSerial = 0;
        this.linkFromSerial = 0;
        this.createdBy = 0;
        this.updatedBy = 0;
        this.status = 0;
        this.installOnBuilding = -1;
        this.duplicateSerialOverride = 0;
        this.purchaseSerial = 0;
        this.purchaseFileName = '';
        this.proofAddressSerial = 0;
        this.panelPhotoSerial = 0;
        this.proofAddressFileName = '';
        this.additionalDocumentsSerial = 0;
        this.additionalDocumentsFileName = '';
        this.complianceSerial = 0;
        this.complianceFileName = '';
        this.gstInvoiceSerial = 0;
        this.gstInvoiceFileName = '';
        this.tax1InvoiceSerial = 0;
        this.tax1InvoiceFileName = '';
        this.tax2InvoiceSerial = 0;
        this.tax2InvoiceFileName = '';
        this.recFormSerial = 0;
        this.recFormFileName = '';
        this.invoiceFormSerial = 0;
        this.invoiceFormFileName = '';
        this.nominationFormSignedSerial = 0;
        this.nominationFormSignedFileName = '';
        this.originalUnitBeforeDecommissioningPhotoSerial = 0;
        this.originalUnitBeforeDecommissioningPhotoFileName = '';
        this.originalUnitsTankLabelPhotoSerial = 0;
        this.originalUnitsTankLabelPhotoFileName = '';
        this.originalUnitOnceRemovedDecommissionedPhotoSerial = 0;
        this.originalUnitOnceRemovedDecommissionedPhotoFileName = '';
        this.newUnitInstalledPhotoSerial = 0;
        this.newUnitInstalledPhotoFileName = '';
        this.newUnitsTankLabelSerial = 0;
        this.newUnitsTankLabelFileName = '';
        this.postImplementationFormSignedSerial = 0;
        this.postImplementationFormSignedFileName = '';
        this.eftposBankReceiptSerial = 0;
        this.eftposBankReceiptFileName = '';
        this.signatureSelfiePhotoSerial = 0;
        this.signatureSelfiePhotoFileName = '';
        this.signatureSelfieMiddleSerial = 0;
        this.signatureSelfieMiddleFileName = '';
        this.signatureSelfieEndSerial = 0;
        this.signatureSelfieEndFileName = '';
        this.electricityBillSerial = 0;
        this.electricityBillFileName = '';
        this.stcFormSerial = 0;
        this.stcFormFileName = '';
        this.escFormSerial = 0;
        this.escFormFileName = '';
        this.veecFormSerial = 0;
        this.veecFormFileName = '';
        this.tankPanelSerialNumberFileName = '';
        this.tankPanelSerialNumberSerial = 0;
        this.electricalCertificateCompliancePhotoSerial = 0;
        this.electricalCertificateCompliancePhotoFileName = '';
        this.photoSerial = 0;
        this.housePhotoSerial = 0;
        this.ownerOrganisationName = '';
        this.ownerFirstName = '';
        this.ownerLastName = '';
        this.ownerUnitType = -1;
        this.ownerUnitNumber = '';
        this.ownerStreetNumber = '';
        this.ownerStreetName = '';
        this.ownerStreetType = 0;
        this.ownerSuburb = '';
        this.ownerPostcode = '';
        this.ownerState = 0;
        this.ownerStateName = '';
        this.ownerPhoneBH = '';
        this.ownerPhoneAH = '';
        this.ownerEmail = '';
        this.ownerLocation = '';
        this.installSameAsPostal = 0;
        this.installPropertyName = '';
        this.installPropertyType = 0;
        this.installStories = 0;
        this.installUnitType = -1;
        this.installUnitNumber = '';
        this.installStreetNumber = '';
        this.installStreetName = '';
        this.installStreetType = 0;
        this.installSuburb = '';
        this.installPostcode = '';
        this.installState = 0;
        this.installLatitude = '';
        this.installLongitude = '';
        this.installMultiple = 0;
        this.installAdditionalInformation = '';
        this.installPreviousFailed = 0;
        this.installPreviousFailedNotes = '';
        this.installLocation = '';
        this.specialAddress = '';
        this.brand = '';
        this.model = '';
        this.brand2 = '';
        this.model2 = '';
        this.inverterBrand = '';
        this.inverterSeries = '';
        this.inverterModel = '';
        this.respSupp = '';
        this.inverterPhotoSerial = 0;
        this.inverterBrand2 = '';
        this.inverterSeries2 = '';
        this.inverterModel2 = '';
        this.inverterSerialNumber = 0;
        this.numberPanels = 1;
        this.numberInverters = 1;
        this.invertersSameModel = 1;
        this.capacity = 0;
        this.inverterCapacity = 0;
        this.retailerName = '';
        this.retailerABN = '';
        this.installationType = 0;
        this.installationTypeInformation = '';
        this.signedDataPackageFileName = '';
        this.wattsPerPanel = 0;
        this.wattsPerPanel2 = 0;
        this.completeUnit = -1;
        this.additionalCapacityDetails = '';
        this.connected = 0;
        this.standardsStatement = 0;
        this.installerSerial = 0;
        this.installerClassification = -1;
        this.installerDetail = new User();
        this.electricianID = 0;
        this.paymentType = -1;
        this.paymentName = '';
        this.paymentEFT = -1;
        this.paymentBSB = '';
        this.paymentAccountNumber = '';
        this.paymentAccountName = '';
        this.paymentPersonalUse = -1;
        this.paymentBusinessName = '';
        this.paymentABN = '';
        this.paymentRECs = 0;
        this.paymentPrice = 0.00;
        this.readTermsAndConditions = 0;
        this.connectedStandards = 0;
        this.standAloneStandards = 0;
        this.ownerSignedName = '';
        this.ownerSignatureSerial = 0;
        this.ownerSignature = '';
        this.ownerPhotoSerial = 0;
        this.ownerPhoto = '';
        this.ownerSignedDigitally = 0;
        this.installerSignedName = '';
        this.installerSignatureSerial = 0;
        this.installerSignature = '';
        this.installerPhotoSerial = 0;
        this.installerPhoto = '';
        this.designerSignedName = '';
        this.designerSignatureSerial = 0;
        this.designerSignature = '';
        this.witnessSignedName = '';
        this.witnessSignature = '';
        this.reductionType = -1;
        this.pricePerSTC = 0.00;
        this.priceFix = 0.00;
        this.includeGST = 0;
        this.priceFixIncGST = 0.00;
        this.priceSTCIncGST = 0.00;
        this.authorizedSignedName = '';
        this.compliancePlateSerial = 0;
        this.inverterCompliancePlateSerial = 0;
        this.accountManager = 0;
        this.batteryAggregatedControl = -1;
        this.batteryIntallerChanged = -1;
        this.numberBatteries = 0;
        this.sameBatteries = -1;
        this.samePanels = -1;
        this.differentPanels = 0;
        this.type = ASSIGNMENT_TYPE_PVD;
        this.installStateName = '';
        this.batteries = [];
        this.panels = [];
        this.removePanels = [];
        this.removeBatteries = [];
        this.inverters = [];
        this.removeInverters = [];
        this.modelType = -1;
        //INSTALLER
        this.installerFirstName = '';
        this.installerLastName = '';
        this.installerAccreditation = '';
        this.installerUnitType = -1;
        this.installerUnitNumber = '';
        this.installerStreetNumber = '';
        this.installerStreetName = '';
        this.installerStreetType = 0;
        this.installerSuburb = '';
        this.installerState = 0;
        this.installerPostcode = '';
        this.installerLicence = '';
        this.installerPhone = '';
        //DESIGNER
        this.designerFirstName = '';
        this.designerLastName = '';
        this.designerAccreditation = '';
        this.designerUnitType = -1;
        this.designerUnitNumber = '';
        this.designerStreetNumber = '';
        this.designerStreetName = '';
        this.designerStreetType = 0;
        this.designerSuburb = '';
        this.designerState = 0;
        this.designerPostcode = '';
        this.designerLicence = '';
        this.designerPhone = '';
        //ELECTRICIAN
        this.electricianFirstName = '';
        this.electricianLastName = '';
        this.electricianUnitType = -1;
        this.electricianUnitNumber = '';
        this.electricianStreetNumber = '';
        this.electricianStreetName = '';
        this.electricianStreetType = 0;
        this.electricianSuburb = '';
        this.electricianState = 0;
        this.electricianPostcode = '';
        this.electricianLicence = '';
        this.electricianPhone = '';
        this.invalidPanel = false;
        this.invalidInverter = false;
        this.invalidInverter2 = false;
        this.editableAssignment = false;
        this.isStaff = false;
        this.paymentFormValid = false;
        this.addressFormValid = false;
        //VEEC/ESS
        this.claimingESCorVEEC = false;
        this.paymentESCorVEEC = 0;
        this.numberESCorVEEC = 0;
        this.isColdroomActivity = false;
        //Electricity changes
        this.isHEEREligible = false;
        this.electricityBill = '';
        this.electricityBillValue = 0;
        this.electricityBillMonthRange = 1;
        this.status = status;
        this.serialNumbers = new Array();
    }
    Object.defineProperty(PVDAssignmentItem.prototype, "ownerFullName", {
        get: function () {
            if (this.ownerType == OWNERTYPE_INDIVIDUAL_ID) {
                return this.ownerFirstName + ' ' + this.ownerLastName;
            }
            return this.ownerOrganisationName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PVDAssignmentItem.prototype, "fullInstallationAddress", {
        get: function () {
            return this._installationAddress ? this._installationAddress : this.formatInstallationAddress();
        },
        enumerable: true,
        configurable: true
    });
    ;
    PVDAssignmentItem.prototype.deserialize = function (input) {
        Object.assign(this, input);
        this.ownerEmail = input.ownerEmail;
        return this;
    };
    PVDAssignmentItem.prototype.init = function (input) {
        Object.assign(this, input);
        return this;
    };
    PVDAssignmentItem.prototype.formatInstallationAddress = function () {
        var _this = this;
        var unit = '';
        var street = '';
        var suburb = '';
        var postcode = '';
        var stateAbbreviation = '';
        var unitType = '';
        this._installationAddress = '';
        if (this.installUnitType && 0 < this.installUnitType && this.installUnitType <= STREETTYPE.length) {
            unitType = UNITTYPE.filter(function (aUnitType) { return aUnitType.id == _this.installUnitType; })[0].name;
        }
        unit = unitType + ' ' + this.installUnitNumber;
        if (this.installStreetType && 0 < this.installStreetType && this.installStreetType <= STREETTYPE.length) {
            var streetType = STREETTYPE.filter(function (aStreetType) { return aStreetType.id == _this.installStreetType; })[0].name;
            street = this.installStreetNumber + ' ' + this.installStreetName + ' ' + streetType;
        }
        suburb = this.installSuburb;
        if (this.installState && this.installState > 0) {
            stateAbbreviation = AU_STATES_WITH_SHORTNAME.filter(function (aState) { return aState.id == _this.installState; })[0].abbreviation;
        }
        postcode = this.installPostcode;
        if (unit && unit.trim().length > 0) {
            this._installationAddress = unit + ', ';
        }
        if (street && street.trim().length > 0) {
            this._installationAddress += street + ', ';
        }
        if (suburb && suburb.trim().length > 0) {
            this._installationAddress += suburb + ', ';
        }
        if (stateAbbreviation && stateAbbreviation.trim().length > 0) {
            this._installationAddress += stateAbbreviation + ', ';
        }
        this._installationAddress = this._installationAddress += postcode;
        return this._installationAddress;
    };
    return PVDAssignmentItem;
}());
export { PVDAssignmentItem };
var PVDHistory = /** @class */ (function () {
    function PVDHistory() {
    }
    return PVDHistory;
}());
export { PVDHistory };
var PVDNote = /** @class */ (function () {
    function PVDNote() {
    }
    return PVDNote;
}());
export { PVDNote };
var NoteProperties = /** @class */ (function () {
    function NoteProperties() {
    }
    return NoteProperties;
}());
export { NoteProperties };
var Battery = /** @class */ (function () {
    function Battery(id, assignmentSerial, storageManufactured, storageModel, statusBattery) {
        this.id = id;
        this.assignmentSerial = assignmentSerial;
        this.storageManufactured = storageManufactured;
        this.storageModel = storageModel;
        this.statusBattery = statusBattery;
    }
    return Battery;
}());
export { Battery };
var Panel = /** @class */ (function () {
    // tslint:disable-next-line: max-line-length
    function Panel(id, assignmentSerial, brand, model, supplier, capacity, numberPanels, wattsPerPanel, statusPanel, messageStatus) {
        this.id = id;
        this.assignmentSerial = assignmentSerial;
        this.brand = brand;
        this.model = model;
        this.supplier = supplier;
        this.capacity = capacity;
        this.numberPanels = numberPanels;
        this.wattsPerPanel = wattsPerPanel;
        this.statusPanel = statusPanel;
        this.messageStatus = messageStatus;
    }
    return Panel;
}());
export { Panel };
var Inverter = /** @class */ (function () {
    function Inverter(id, assignmentSerial, inverterBrand, inverterSeries, inverterModel, statusInverter, messageStatus) {
        this.id = id;
        this.assignmentSerial = assignmentSerial;
        this.inverterBrand = inverterBrand;
        this.inverterSeries = inverterSeries;
        this.inverterModel = inverterModel;
        this.statusInverter = statusInverter;
        this.messageStatus = messageStatus;
    }
    return Inverter;
}());
export { Inverter };
var additionalPhoto = /** @class */ (function () {
    function additionalPhoto() {
    }
    return additionalPhoto;
}());
export { additionalPhoto };
var PanelModel = /** @class */ (function () {
    function PanelModel() {
    }
    return PanelModel;
}());
export { PanelModel };
var PanelBrandModel = /** @class */ (function () {
    function PanelBrandModel() {
    }
    return PanelBrandModel;
}());
export { PanelBrandModel };
var InverterSeriesModel = /** @class */ (function () {
    function InverterSeriesModel() {
    }
    return InverterSeriesModel;
}());
export { InverterSeriesModel };
var InverterModel = /** @class */ (function () {
    function InverterModel() {
    }
    return InverterModel;
}());
export { InverterModel };
var RespSuppModel = /** @class */ (function () {
    function RespSuppModel() {
    }
    return RespSuppModel;
}());
export { RespSuppModel };
var AddressModel = /** @class */ (function () {
    function AddressModel() {
    }
    return AddressModel;
}());
export { AddressModel };
var InstallerModel = /** @class */ (function () {
    function InstallerModel() {
    }
    return InstallerModel;
}());
export { InstallerModel };
var ElectricianModel = /** @class */ (function () {
    function ElectricianModel() {
    }
    return ElectricianModel;
}());
export { ElectricianModel };
var AccountManagerModel = /** @class */ (function () {
    function AccountManagerModel() {
    }
    return AccountManagerModel;
}());
export { AccountManagerModel };
var SettingsModel = /** @class */ (function () {
    function SettingsModel() {
    }
    return SettingsModel;
}());
export { SettingsModel };
var SignatureModel = /** @class */ (function () {
    function SignatureModel(signedName, signedDate, validForm) {
        if (signedName === void 0) { signedName = ''; }
        if (signedDate === void 0) { signedDate = null; }
        if (validForm === void 0) { validForm = false; }
        this.signedName = signedName;
        this.signedDate = signedDate;
        this.validForm = validForm;
    }
    return SignatureModel;
}());
export { SignatureModel };
var FileModel = /** @class */ (function () {
    function FileModel(serial, name) {
        if (serial === void 0) { serial = 0; }
        if (name === void 0) { name = ''; }
        this.serial = serial;
        this.name = name;
    }
    return FileModel;
}());
export { FileModel };
var SerialNumbersInfo = /** @class */ (function () {
    function SerialNumbersInfo(serialNumber, duplicate, verified) {
        if (serialNumber === void 0) { serialNumber = ''; }
        if (duplicate === void 0) { duplicate = false; }
        if (verified === void 0) { verified = false; }
        this.serialNumber = serialNumber;
        this.duplicate = duplicate;
        this.verified = verified;
    }
    return SerialNumbersInfo;
}());
export { SerialNumbersInfo };
