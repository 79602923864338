import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import * as moment from "moment";
import { MatSnackBar } from '@angular/material';
import { FORMTRACK_URL, FT_POST } from '../../environments/environment';
import { DATE_FORMAT } from 'src/environments/constants';
import { FormtrackError } from '../modules/shared/models/formtrack.http';
import { SearchFilter } from '../modules/shared/models/filter';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/material/snack-bar";
var ApiService = /** @class */ (function () {
    function ApiService(_http, snackBar) {
        this._http = _http;
        this.snackBar = snackBar;
        this.addressCache = new Array();
        this.datePipeEn = new DatePipe('en-Au');
    }
    ApiService.prototype.cacheAddresses = function () {
        var _this = this;
        var filters = new SearchFilter();
        filters.value = '';
        this.get('greenbank', 'setting', 'getLocationsForAutoComplete', filters).subscribe(function (res) {
            if (res != null) {
                Object.assign(_this.addressCache, res.data);
            }
        }, function (err) {
            console.log("Error retrieving cache.");
        });
    };
    ApiService.prototype.get = function (instance, service, method, params, filterType, isObject) {
        var _this = this;
        if (isObject === void 0) { isObject = false; }
        var param = this.buildRequest(instance, service, method, params, filterType, isObject);
        if (param.keys)
            return this._http.get(FORMTRACK_URL, { params: param })
                .pipe(map(function (i) {
                if (i && i.error != null && i.error.message != null) {
                    _this.snackBar.open(i.error.details, 'Close', {
                        duration: 2500,
                    });
                    throw new FormtrackError(i.error.errorCode, i.error.message, i.error.details, i.error.data);
                }
                else {
                    if (i == null) {
                        return EMPTY;
                    }
                    return i.result;
                }
            }));
    };
    ApiService.prototype.post = function (instance, service, method, body, RESTful, buildForm) {
        var _this = this;
        if (RESTful === void 0) { RESTful = false; }
        if (buildForm === void 0) { buildForm = false; }
        if (RESTful) {
            var formData = this.buildFormData(body);
            return this._http.post(FT_POST + instance + '/' + service + '/' + method, formData, { params: new HttpParams() });
        }
        else {
            body = buildForm ? this.objectToFormData(body, 'arguments') : body;
            return this._http.post(FORMTRACK_URL, body, { params: this.buildRequest(instance, service, method) })
                .pipe(map(function (i) {
                if (i && i.error != null && i.error.message != null) {
                    _this.snackBar.open(i.error.details, 'Close', {
                        duration: 2500,
                    });
                    return i.error;
                    // throw new FormtrackError(i.error.errorCode, i.error.message, i.error.details, i.error.data);
                }
                else {
                    return i.result;
                }
            }));
        }
    };
    ApiService.prototype.buildFormData = function (data) {
        var formData = new FormData();
        for (var i in data) {
            if (data[i] instanceof Object) {
                if (data[i] instanceof moment) {
                    if (data[i])
                        formData.append(i, data[i].format(DATE_FORMAT));
                }
                else if (data[i].length > 0) {
                    var temp = [];
                    for (var e = 0; e < data[i].length; e++) {
                        temp.push(data[i][e]);
                    }
                    if (data[i])
                        formData.append(i, JSON.stringify(temp));
                }
                else {
                    formData.append(i, data[i]);
                }
            }
            else {
                if (data[i] != null)
                    formData.append(i, data[i].toString());
            }
        }
        return formData;
    };
    ApiService.prototype.objectToFormData = function (obj, namespace, form) {
        if (form === void 0) { form = new FormData(); }
        var formKey;
        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (namespace) {
                    formKey = namespace + '[' + property + ']';
                }
                else {
                    formKey = property;
                }
                // if the property is an object, but not a File,
                // use recursivity.
                if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                    this.objectToFormData(obj[property], formKey, form);
                }
                else {
                    // if it's a string or a File object
                    form.append(formKey, obj[property]);
                }
            }
        }
        return form;
    };
    ;
    ApiService.prototype.buildRequest = function (instanceName, serviceName, functionName, params, filters, isObject) {
        if (filters === void 0) { filters = ''; }
        if (isObject === void 0) { isObject = false; }
        var httpParams = new HttpParams()
            .set('instanceName', instanceName)
            .set('serviceName', serviceName)
            .set('functionName', functionName);
        if (params != null) {
            if (isObject) {
                httpParams = new HttpParams({ fromObject: params })
                    .set('instanceName', instanceName)
                    .set('serviceName', serviceName)
                    .set('functionName', functionName);
            }
            else {
                for (var i in params) {
                    switch (i) {
                        case "value":
                        case "operator":
                        case "location":
                        case "field":
                        case "installerType":
                        case "ignoreCase":
                            {
                                httpParams = httpParams.set("filter[filters][0][" + i + "]", params[i]);
                                break;
                            }
                        case "personSerial":
                        case "recordTypeSerial":
                        case "accreditationStart":
                        case "accreditationEnd":
                        case "groupSerial":
                        case "accreditation":
                        case "recordType":
                        case "assignmentList":
                        case "assignmentType":
                        case "searchTerm":
                        case "status":
                        case "ownerType":
                        case "name":
                        case "address":
                        case "paymentRECs":
                        case "numberESCorVEEC":
                        case "paymentPrice":
                        case "paymentGST":
                        case "paymentTotal":
                        case "registryStatus":
                        case "paidStatus":
                        case "paymentName":
                        case "accreditationNumber":
                        case "accountManager":
                        case "exportFileName":
                        case "serialNumber":
                        case "submittedByName":
                        case "movedToCheckingByName":
                        case "paymentTypes":
                        case "fromDate":
                        case "toDate":
                        case "myobStatus":
                        case "MYOBUpdatedfromDate":
                        case "MYOBUpdatedtoDate":
                        case "receivedFromDate":
                        case "receivedToDate":
                        case "isolators":
                        case "serial":
                        case "assignmentSerialDisplay":
                        case "personSerial":
                        case "assignmentSerial":
                        case "series":
                        case "brand2":
                        case "series2":
                        case "model2":
                        case "numberOfInverters":
                        case "inverters":
                        case "invertersSameModel":
                        case "installDate":
                        case "panelCapacity":
                        case "gridConnected":
                        case "installOnBuilding":
                        case "createdBy":
                        case "updatedBy":
                        case "installerSerial":
                        case "installer":
                        case "type":
                        case "title":
                        case "text":
                        case "fileSerial": {
                            if (filters == 'filters' || filters == 'arguments') {
                                if (i == 'installerSerial')
                                    httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                                else
                                    httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            }
                            else {
                                if (typeof params[i] === 'string')
                                    httpParams = httpParams.set(i, params[i].replace(/\s/g, "+"));
                                else
                                    httpParams = httpParams.set(i, params[i]);
                            }
                            break;
                        }
                        case "logic": {
                            httpParams = httpParams.set("filter[" + i + "]", params[i]);
                            break;
                        }
                        case "messageTo":
                        case "inboxSerial":
                        case "subject":
                        case "message": {
                            httpParams = params[i] ? httpParams.set("arguments[detail][" + i + "]", params[i]) : httpParams;
                            break;
                        }
                        case "id": {
                            httpParams = params[i] ? httpParams.set("arguments[serial]", params[i]) : httpParams;
                            break;
                        }
                        case "assignmentSerialApprove": {
                            httpParams = params[i] ? httpParams.set("arguments[data][assignmentSerial]", params[i]) : httpParams;
                            break;
                        }
                        case "assignmentSerialSubmit": {
                            httpParams = params[i] ? httpParams.set("arguments[data][assignmentSerial]", params[i]) : httpParams;
                            break;
                        }
                        case "dealRatePrice": {
                            httpParams = params[i] ? httpParams.set("arguments[data][dealRatePrice]", params[i]) : httpParams;
                            break;
                        }
                        case "statusVerify": {
                            httpParams = params[i] ? httpParams.set("arguments[data][status]", params[i]) : httpParams;
                            break;
                        }
                        case "assignmentSerialVerify": {
                            params[i].forEach(function (serial) {
                                httpParams = params[i] ? httpParams.append("arguments[data][assignmentSerials][]", serial) : httpParams;
                            });
                            break;
                        }
                        case "pvdSerials": {
                            params[i].forEach(function (serial) {
                                httpParams = params[i] ? httpParams.append("arguments[data][pvdSerials][]", serial) : httpParams;
                            });
                            break;
                        }
                        case "swhSerials": {
                            params[i].forEach(function (serial) {
                                httpParams = params[i] ? httpParams.append("arguments[data][swhSerials][]", serial) : httpParams;
                            });
                            break;
                        }
                        case "checkPVDSerial": {
                            httpParams = params[i] ? httpParams.set("arguments[data][serials][]", params[i]) : httpParams;
                            break;
                        }
                        case "checkPVDAssigmentSerial": {
                            httpParams = params[i] ? httpParams.set("arguments[data][assignmentSerial]", params[i]) : httpParams;
                            break;
                        }
                        case "checkSWHSerial": {
                            httpParams = params[i] ? httpParams.set("arguments[data][serial]", params[i]) : httpParams;
                            break;
                        }
                        case "checkSWHAssigmentSerial": {
                            httpParams = params[i] ? httpParams.set("arguments[data][assignmentSerial]", params[i]) : httpParams;
                            break;
                        }
                        case "exportCSVSerials": {
                            httpParams = params[i] ? httpParams.set("arguments[serials][]", params[i]) : httpParams;
                            break;
                        }
                        case "assignmentSerialStatus": {
                            httpParams = params[i] ? httpParams.set("arguments[data][status]", params[i]) : httpParams;
                            break;
                        }
                        case "ownerPostcodeGetRECs": {
                            httpParams = params[i] ? httpParams.set("arguments[data][ownerPostcode]", params[i]) : httpParams;
                            break;
                        }
                        case "installPostcodeGetRECs": {
                            httpParams = params[i] ? httpParams.set("arguments[data][installPostcode]", params[i]) : httpParams;
                            break;
                        }
                        case "installSameAsPostalGetRECs": {
                            httpParams = params[i] ? httpParams.set("arguments[data][installSameAsPostal]", params[i]) : httpParams;
                            break;
                        }
                        case "numberPanelsGetRECs": {
                            httpParams = params[i] ? httpParams.set("arguments[data][numberPanels]", params[i]) : httpParams;
                            break;
                        }
                        case "wattsPerPanelGetRECs": {
                            httpParams = params[i] ? httpParams.set("arguments[data][wattsPerPanel]", params[i]) : httpParams;
                            break;
                        }
                        case "capacityGetRECs": {
                            httpParams = params[i] ? httpParams.set("arguments[data][capacity]", params[i]) : httpParams;
                            break;
                        }
                        case "installDateGetRECs": {
                            httpParams = params[i] ? httpParams.set("arguments[data][installDate]", params[i]) : httpParams;
                            break;
                        }
                        case "brandGetRECs": {
                            httpParams = params[i] ? httpParams.set("arguments[data][brand]", params[i]) : httpParams;
                            break;
                        }
                        case "modelGetRECs": {
                            httpParams = params[i] ? httpParams.set("arguments[data][model]", params[i]) : httpParams;
                            break;
                        }
                        case "fieldSQL": {
                            httpParams = httpParams.set("arguments[fieldSQL]", params[i]);
                            break;
                        }
                        case "nameFieldSQL": {
                            httpParams = httpParams.set("arguments[nameFieldSQL]", params[i]);
                            break;
                        }
                        case "assignmentArray": {
                            httpParams = httpParams.set("arguments[assignmentArray]", params[i]);
                            break;
                        }
                        case "assingType": {
                            httpParams = httpParams.set("arguments[astype]", params[i]);
                            break;
                        }
                        case "contactRecordTypeFilter": {
                            httpParams = httpParams.set("filters[recordType]", params[i]);
                            break;
                        }
                        case "contactSearchTermFilter": {
                            httpParams = httpParams.set("filters[searchTerm]", params[i]);
                            break;
                        }
                        case "contactAccreditationFilter": {
                            httpParams = httpParams.set("filters[accreditation]", params[i]);
                            break;
                        }
                        case "contactAccreditationStartFilter": {
                            // httpParams = moment(params[i]).isValid() ? httpParams.set("filters[accreditationStart]", params[i].format(DATE_FORMAT)) : httpParams.set("filters[accreditationStart]", '');
                            httpParams = params[i] ? httpParams.set("filters[accreditationStart]", moment(params[i]).format(DATE_FORMAT)) : httpParams.set("filters[accreditationStart]", '');
                            break;
                        }
                        case "contactAccreditationEndFilter": {
                            //httpParams =moment(params[i]).isValid() ? httpParams.set("filters[accreditationEnd]", params[i].format(DATE_FORMAT)) : httpParams.set("filters[accreditationEnd]", '');
                            httpParams = params[i] ? httpParams.set("filters[accreditationEnd]", moment(params[i]).format(DATE_FORMAT)) : httpParams.set("filters[accreditationEnd]", '');
                            break;
                        }
                        case "contactLicenceFilter": {
                            httpParams = httpParams.set("filters[licence]", params[i]);
                            break;
                        }
                        case "brandArg": {
                            if (i && params[i]) {
                                httpParams = httpParams.set('brand', params[i].replace(/\s/g, "+"));
                            }
                            break;
                        }
                        case 'sectionSerialCSV': {
                            httpParams = httpParams.set("arguments[params][sectionSerial]", params[i]);
                            break;
                        }
                        case 'profileTypeCSV': {
                            httpParams = httpParams.set("arguments[params][profileType]", params[i]);
                            break;
                        }
                        case 'asArrayCSV': {
                            httpParams = httpParams.set("arguments[params][asArray]", params[i]);
                            break;
                        }
                        case "Serial": {
                            httpParams = httpParams.set("serial", params[i]);
                            break;
                        }
                        case "AssignmentSerial": {
                            httpParams = httpParams.set("assignmentSerial", String(params[i]));
                            break;
                        }
                        case "Brand": {
                            httpParams = httpParams.set("brand", params[i]);
                            break;
                        }
                        case "Model": {
                            httpParams = httpParams.set("model", params[i]);
                            break;
                        }
                        case "brand": {
                            httpParams = httpParams.set(filters + "[data][" + i + "]", params[i]);
                            break;
                        }
                        case "model": {
                            httpParams = httpParams.set(filters + "[data][" + i + "]", params[i]);
                            break;
                        }
                        case "replacedSystemType": {
                            httpParams = httpParams.set(filters + "[data][" + i + "]", params[i]);
                            break;
                        }
                        case "isColdRoomBelowZero":
                        case "coldroomInternalFloorArea":
                        case "replacedUnitTotalRatedCapacity": {
                            httpParams = httpParams.set(filters + "[data][" + i + "]", params[i]);
                            break;
                        }
                        case "assignmentSerial": {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case "Location": {
                            httpParams = httpParams.set("location", params[i]);
                            break;
                        }
                        case 'identifier': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case 'moduleInstance': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case 'stateSerial': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case 'sectionSerial': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case 'typeId': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case 'recordSerial': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case 'noteSerial': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            httpParams = httpParams.set(filters + "[details][" + i + "]", params[i]);
                            break;
                        }
                        case 'returnArray': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case 'noteTypeSerial': {
                            httpParams = httpParams.set(filters + "[details][" + i + "]", params[i]);
                            break;
                        }
                        case 'noteTitle': {
                            httpParams = httpParams.set(filters + "[details][" + i + "]", params[i]);
                            break;
                        }
                        case 'noteText': {
                            httpParams = httpParams.set(filters + "[details][" + i + "]", params[i]);
                            break;
                        }
                        case 'serial': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case 'recPriceSerial': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case 'stcNon': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        } /*new columns 15/5/2022*/
                        case 'escNon': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case 'veecNon': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case 'claimingESCorVEEC': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case 'paymentESCorVEEC': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case 'numberESCorVEEC': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        /*end new*/
                        case 'formtrackStcNon': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case 'date': {
                            var dateString = moment(params[i]).format(DATE_FORMAT);
                            httpParams = httpParams.set(filters + "[" + i + "]", dateString);
                            break;
                        }
                        case 'public': {
                            httpParams = httpParams.set(filters + "[" + i + "]", params[i]);
                            break;
                        }
                        case 'assignmentSerialDuplicate': {
                            httpParams = httpParams.set(filters + "[data][assignmentSerial]", params[i]);
                            break;
                        }
                        case 'unitNumber': {
                            httpParams = httpParams.set(filters + "[data][" + i + "]", params[i]);
                            break;
                        }
                        case 'unitType': {
                            httpParams = httpParams.set(filters + "[data][" + i + "]", params[i]);
                            break;
                        }
                        case 'streetNumber': {
                            httpParams = httpParams.set(filters + "[data][" + i + "]", params[i]);
                            break;
                        }
                        case 'streetName': {
                            httpParams = httpParams.set(filters + "[data][" + i + "]", params[i]);
                            break;
                        }
                        case 'streetType': {
                            httpParams = httpParams.set(filters + "[data][" + i + "]", params[i]);
                            break;
                        }
                        case 'suburb': {
                            httpParams = httpParams.set(filters + "[data][" + i + "]", params[i]);
                            break;
                        }
                        case 'state': {
                            httpParams = httpParams.set(filters + "[data][" + i + "]", params[i]);
                            break;
                        }
                        case 'postcode': {
                            httpParams = httpParams.set(filters + "[data][" + i + "]", params[i]);
                            break;
                        }
                        case "installPostcode": {
                            httpParams = httpParams.set(filters + "[data][" + i + "]", params[i]);
                            break;
                        }
                        case "installType": {
                            httpParams = httpParams.set(filters + "[data][" + i + "]", params[i]);
                            break;
                        }
                        case "installationDate": {
                            //Need to set the installDate for a data parameter
                            httpParams = httpParams.set(filters + "[data][installDate]", params[i]);
                            break;
                        }
                        case "exportbuttonClaimType": {
                            httpParams = params[i] ? httpParams.set("arguments[claimType]", params[i]) : httpParams;
                            break;
                        }
                        case "exportbuttonStartDate": {
                            httpParams = params[i] ? httpParams.set("arguments[startDate]", params[i]) : httpParams;
                            break;
                        }
                        case "exportbuttonStatusTypes": {
                            httpParams = params[i] ? httpParams.set("arguments[statusTypes]", params[i]) : httpParams;
                            break;
                        }
                        case "exportPartnerProgramOrganisations": {
                            httpParams = params[i] ? httpParams.set("arguments[partnerProgramOrganisations]", params[i]) : httpParams;
                            break;
                        }
                        case "exportOrganisationSerial": {
                            httpParams = params[i] ? httpParams.set("arguments[exportOrganisationSerial]", params[i]) : httpParams;
                            break;
                        }
                        case "exportOrganisationName": {
                            httpParams = params[i] ? httpParams.set("arguments[exportOrganisationName]", params[i]) : httpParams;
                            break;
                        }
                        case "installerList": {
                            httpParams = params[i] ? httpParams.set("arguments[installerList]", params[i]) : httpParams;
                            break;
                        }
                        case "assignmentFileSerials": {
                            httpParams = params[i] ? httpParams.set("arguments[fileSerials]", params[i]) : httpParams;
                            break;
                        }
                        default: {
                            if (typeof params[i] === 'string')
                                httpParams = httpParams.set(i, params[i].replace(/\s/g, "+"));
                            else
                                httpParams = httpParams.set(i, params[i]);
                            break;
                        }
                    }
                }
            }
        }
        return httpParams;
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.MatSnackBar)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
