<div class="container-view">
    <div class="company-detail">
        <img src="/assets/images/greenbank_logo.png" alt="companyName" /><br>
        {{companyName}}<br>
        PO BOX 224, Nunawading Vic 3131<br>
        Ph: 03 9845 3000&nbsp;&nbsp;Fax: 03 9877 4904<br>
        <u>www.green-bank.com.au</u>
    </div>
    <div class="signature-form" *ngIf="assignmentDetail; else handleError;">
        <div class="assignment-detail">
            <h1 class="header-name">STC Assignment Form</h1>
            <div class="field-group">
                <div class="field-name">Name:</div>
                <div class="field-value">{{assignmentDetail.ownerFullName}}</div>
            </div>
            <div class="field-group">
                <div class="field-name">Installation Address:</div>
                <div class="field-value">{{fullInstallationAddress}}</div>
            </div>
            <!-- PVD (Solar PV) Specific -->
            <div class="field-group" *ngIf="assignmentDetail.assignmentType == 1">
                <div class="field-name">Panel Details:</div>
                <div class="field-value">{{panelDetail}}</div>
            </div>
            <div class="field-group" *ngIf="assignmentDetail.assignmentType == 1">
                <div class="field-name">Inverter Details:</div>
                <div class="field-value">{{inverterDetail}}</div>
            </div>
            <!-- PVD (Solar PV) Specific -->
            <!-- SWH (Water Heating) Specific -->
            <div class="field-group" *ngIf="assignmentDetail.assignmentType == 0">
                <div class="field-name">Solar System Description:</div>
                <div class="field-value">{{assignmentDetail.brand}}, {{assignmentDetail.model}}</div>
            </div>
            <div class="field-group" *ngIf="assignmentDetail.assignmentType == 0">
                <div class="field-name">Tank Serial Number:</div>
                <div class="field-value">{{assignmentDetail.serialNumber}}</div>
            </div>
            <!-- SWH (Water Heating) Specific -->
            <div class="field-group">
                <div class="field-name">Installation Date:</div>
                <div class="field-value">{{assignmentDetail.installDate}}</div>
            </div>
            <div class="field-group">
                <div class="field-name">Installer/Designer Name:</div>
                <div class="field-value">{{assignmentDetail.installerFullName}}</div>
            </div>
            <div class="field-group">
                <div>Payment to&nbsp;<span class="field-name">{{assignmentDetail.paymentName}}</span>&nbsp;On your
                    behalf for the installation of your system</div>
            </div>
        </div>
        <div class="mandatory-declaration">
            <h3 class="header-name">Installation and Mandatory Declaration</h3>
            <ul>
                <li *ngFor="let aDeclaration of mandatoryDeclaration" [innerHTML]="aDeclaration"></li>
            </ul>
        </div>
        <div class="terms-condition">
            <h3 class="header-name">Terms & Conditions</h3>
            <ul>
                <li *ngFor="let aTerm of assignmentDetail.termsAndConditionList" [innerHTML]="aTerm"></li>
            </ul>
        </div>
        <div class="user-action">
            <mat-checkbox [(ngModel)]="isAgreed" name="agree">I have read and understand all the terms and conditions on
                page 2 of this form or on the website at <a
                    href="www.green-bank.com.au/legal-notices">green-bank.com.au</a> and am aware that penalties apply
                for providing misleading information under the Renewable Energy (Electricity) Act 2000.</mat-checkbox>
            <div class="field-group">
                <div class="field-name">Full Name:</div>
                <div class="field-value">
                    <mat-form-field appearance="outline">
                        <input matInput type="text" [ngModel]="ownerSignedName"
                            (ngModelChange)="ownerSignedName=transformTitlecase($event)" name="ownerName"
                            placeholder="Full Name">
                    </mat-form-field>
                </div>
            </div>
            <div class="field-group">
                <div class="field-name">Date:</div>
                <div class="field-value">{{assignmentDetail.currentDate}}</div>
            </div>
        </div>
        <button mat-flat-button color="primary" [disabled]="!isAgreed || !ownerSignedName" type="submit"
            (click)="submitSignature()">Submit</button>
    </div>
    <ng-template #handleError>
        <div class="system-message">{{sysMessage}}</div>
    </ng-template>
</div>