import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import * as moment from "moment";
import { ApiService } from './api.service';
import { SITE_URL } from '../../environments/environment';
import { TYPE_CLIENT, TYPE_STAFF, DATE_FORMAT_GRID } from 'src/environments/constants';
import { User, Company } from '../modules/shared/models/user';
import Bugsnag from '@bugsnag/js';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./api.service";
var AuthService = /** @class */ (function () {
    function AuthService(router, _api) {
        this.router = router;
        this._api = _api;
        this.user = new User();
        this.company = new Company();
        this.loading = false;
        this.wrongCredential = false;
        // public $userSignedIn: Subject<boolean> = new Subject<boolean>();
        this.redirectUrl = '';
        this.companyFetched = new EventEmitter();
        this.user = new User();
    }
    AuthService.prototype.submitLoginInfo = function (credential) {
        var _this = this;
        return this._api.post('flexwrapper', 'login', 'submitLoginInfo', credential)
            .subscribe(function (res) {
            _this.wrongCredential = false;
            _this.user.recordTypeSerial = res.recordType;
            localStorage.clear();
            _this.storeSession(res, credential.rememberMe);
            _this.getProfile(res, credential.rememberMe).subscribe(function (res) {
                if (_this.user.recordTypeSerial != TYPE_CLIENT && _this.user.recordTypeSerial != TYPE_STAFF) {
                    _this.wrongCredential = true;
                    _this.loading = false;
                    _this._api.snackBar.open("You do not have permission to log into this portal.", 'Close', {
                        duration: 2500,
                    });
                    return;
                }
                if (_this.isStaff()) {
                    if (_this.redirectUrl != '') {
                        _this.router.navigate([_this.redirectUrl]);
                        _this.redirectUrl = '';
                    }
                    else
                        _this.router.navigate(['/admin/assignments']);
                }
                else {
                    if (_this.redirectUrl != '') {
                        _this.router.navigate([_this.redirectUrl]);
                        _this.redirectUrl = '';
                    }
                    else
                        _this.router.navigate(['/client/assignments']);
                }
            });
        }, function (err) {
            _this.wrongCredential = true;
            _this.loading = false;
        });
    };
    AuthService.prototype.submitReloginInfo = function (credential) {
        var _this = this;
        return this._api.post('flexwrapper', 'login', 'submitReloginInfo', credential)
            .subscribe(function (res) {
            _this.wrongCredential = false;
            _this.user.recordTypeSerial = res.recordType;
            _this.storeSession(res, credential.rememberMe);
            _this.getProfile(res, credential.rememberMe).subscribe(function (res) { });
            if (_this.user.recordTypeSerial != TYPE_CLIENT && _this.user.recordTypeSerial != TYPE_STAFF) {
                _this.wrongCredential = true;
                _this.loading = false;
                _this._api.snackBar.open("You do not have permission to log into this portal.", 'Close', {
                    duration: 2500,
                });
                return;
            }
            if (_this.isStaff()) {
                if (_this.redirectUrl != '') {
                    _this.router.navigate([_this.redirectUrl]);
                    _this.redirectUrl = '';
                }
                else
                    _this.router.navigate(['/admin/assignments']);
            }
            else {
                if (_this.redirectUrl != '') {
                    _this.router.navigate([_this.redirectUrl]);
                    _this.redirectUrl = '';
                }
                else
                    _this.router.navigate(['/client/assignments']);
            }
        }, function (err) {
            _this.wrongCredential = true;
        });
    };
    AuthService.prototype.storeSession = function (session, rememberMe) {
        if (rememberMe) {
            localStorage.setItem('sessionId', session.sessionId);
            localStorage.setItem('recordTypeSerial', session.recordType);
        }
        else {
            sessionStorage.setItem('sessionId', session.sessionId);
            sessionStorage.setItem('recordTypeSerial', session.recordType);
        }
    };
    AuthService.prototype.getProfile = function (session, rememberMe, serial) {
        var _this = this;
        return this._api.get('greenbank', 'user', 'getLoggedInGreenbankUser', serial).pipe(map(function (res) {
            _this.user = res.user;
            Bugsnag.setUser(_this.user.username, _this.user.email, _this.user.givenName + ' ' + _this.user.surname);
            _this.company = res.company;
            if (_this.company.imagePath) {
                _this.checkImageOrientation();
            }
            if (_this.company.dealRateCustomer == 1) {
                if (moment(_this.company.dealStartDate).isValid()) {
                    _this.company.formattedDealStartDate = moment(_this.company.dealStartDate).format(DATE_FORMAT_GRID);
                }
                if (moment(_this.company.dealEndDate).isValid()) {
                    _this.company.formattedDealEndDate = moment(_this.company.dealEndDate).format(DATE_FORMAT_GRID);
                }
                if (moment(_this.company.dealStartDate).isValid() && moment(_this.company.dealEndDate).isValid()) {
                    if (moment(_this.company.dealStartDate).isAfter(moment())) {
                        _this.company.dealDaysRemaining = moment(_this.company.dealEndDate).diff(moment(_this.company.dealStartDate), 'days') + 1;
                    }
                    else {
                        _this.company.dealDaysRemaining = moment(_this.company.dealEndDate).diff(moment(), 'days') + 1;
                    }
                    _this.company.dealDaysRemaining = _this.company.dealDaysRemaining >= 0 ? _this.company.dealDaysRemaining : 0;
                }
                _this.companyFetched.emit(true);
            }
        }, function (err) {
        }));
    };
    AuthService.prototype.checkImageOrientation = function () {
        var _this = this;
        var para = {
            imageOrgPath: SITE_URL + this.company.imagePath
        };
        this._api.get('greenbank', 'setting', 'getImageFormat', para, 'arguments').subscribe(function (res) {
            if (res) {
                _this.company.incorrectImageOrientation = true;
            }
            else {
                _this.company.incorrectImageOrientation = false;
            }
        });
    };
    AuthService.prototype.isAuthenticated = function () {
        if (sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId')) {
            return true;
        }
        else {
            return false;
        }
    };
    AuthService.prototype.isStaff = function () {
        return this.user.recordTypeSerial == TYPE_STAFF || Number(sessionStorage.getItem('recordTypeSerial')) == TYPE_STAFF || Number(localStorage.getItem('recordTypeSerial')) == TYPE_STAFF;
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        this.router.navigate(['/login']);
        this._api.post('flexwrapper', 'login', 'logout', '')
            .subscribe(function (res) {
            window.location.reload();
            sessionStorage.removeItem('sessionId');
            localStorage.removeItem('sessionId');
            localStorage.clear();
            _this.user = new User();
            _this.user.recordTypeSerial = -1;
            // this.$userSignedIn.next(false);
        });
    };
    AuthService.prototype.getSignatureInfo = function (bodyParams) {
        return this._api.post('flexwrapper', 'login', 'getSignatureInfo', bodyParams);
    };
    AuthService.prototype.updateEmailSignature = function (bodyParams) {
        return this._api.post('flexwrapper', 'login', 'updateEmailSignature', bodyParams);
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ApiService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
