<div class="auth-card">
  <div class="header">
    <img src="/assets/images/GBE_logo_stacked.png" alt="Formtrack">
    <h4>Login with your Formtrack account</h4>
  </div>
  <form class="content" #loginForm="ngForm" (ngSubmit)="login()">
    <mat-progress-bar mode="query" *ngIf="_auth.loading"></mat-progress-bar>
    <div class="progress-holder" *ngIf="!_auth.loading"></div>
    <mat-form-field class="top-pad" appearance="outline" [hideRequiredMarker]="true">
      <mat-label>Username</mat-label>
      <input matInput type="text" [(ngModel)]="credential.username" #userName="ngModel" name="userName" required>
      <mat-icon matSuffix>person</mat-icon>
      <mat-error *ngIf="userName.hasError('required')">Username is
        <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" [hideRequiredMarker]="true">
      <mat-label>Password</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="credential.password" #password="ngModel" name="password"
        required>
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error *ngIf="password.hasError('required')">Password is
        <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <div class="actions">
      <mat-checkbox [(ngModel)]="credential.rememberMe" name="rememberMe">Remember me</mat-checkbox>
      <button mat-raised-button color="accent" type="submit">Login</button>
    </div>
    <!-- <img mat-card-image class="img-portal" src="/assets/images/portal.jpg" alt="Paperless Portal"> -->
  </form>
  <div class="forgot">
    <span>Forgot username or password? Call Greenbank on
      <a href="tel:1300 473 362">1300 473 362</a>
    </span>
  </div>
</div>