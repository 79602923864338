import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UNITTYPE, STREETTYPE, AU_STATES_WITH_SHORTNAME } from 'src/environments/constants';
var SignatureFormComponent = /** @class */ (function () {
    function SignatureFormComponent(_auth, _router) {
        this._auth = _auth;
        this._router = _router;
        this.sysMessage = 'Loading...';
        this.companyName = 'GB Environmental Pty Ltd';
        this.isAgreed = false;
        this.ownerSignedName = '';
        this.mandatoryDeclaration = [];
    }
    Object.defineProperty(SignatureFormComponent.prototype, "fullInstallationAddress", {
        get: function () {
            return this._installationAddress ? this._installationAddress : this.formatInstallationAddress();
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(SignatureFormComponent.prototype, "panelDetail", {
        get: function () {
            return this._panelDetail ? this._panelDetail : this.formatPanelDetail();
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(SignatureFormComponent.prototype, "inverterDetail", {
        get: function () {
            return this._inverterDetail ? this._inverterDetail : this.formatInverterDetail();
        },
        enumerable: true,
        configurable: true
    });
    ;
    SignatureFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._router.queryParamMap.subscribe(function (queryParams) {
            _this._emailToken = queryParams.get('token');
            if (_this._emailToken) {
                _this._auth.getSignatureInfo({ token: _this._emailToken }).subscribe(function (res) {
                    if (res.message) {
                        // load error
                        _this.sysMessage = res.details;
                    }
                    else {
                        _this.assignmentDetail = res;
                        _this.assignmentDetail.termsAndConditionList = _this.assignmentDetail.termsAndConditions.split('- ').filter(function (aTerm) {
                            return aTerm.length > 0;
                        });
                        var lastIndex = _this.assignmentDetail.termsAndConditionList.length - 1;
                        _this.assignmentDetail.termsAndConditionList[lastIndex] = _this.assignmentDetail.termsAndConditionList[lastIndex]
                            .replace("www.green-bank.com.au", '<a href="www.green-bank.com.au">www.green-bank.com.au</a>');
                        _this.setupMandatoryDeclaration();
                    }
                });
            }
        });
    };
    SignatureFormComponent.prototype.submitSignature = function () {
        var _this = this;
        this._auth.updateEmailSignature({ token: this._emailToken, ownerSignedName: this.ownerSignedName, isAgreed: this.isAgreed }).subscribe(function (res) {
            if (res) {
                _this.assignmentDetail = null;
                _this.sysMessage = "Signature successfully submitted.\nThank you for submitting the signature form.";
            }
            else {
                // load error
                _this.sysMessage = 'Link Expired!';
            }
        });
    };
    SignatureFormComponent.prototype.transformTitlecase = function (input) {
        if (!input) {
            return '';
        }
        else {
            return input.replace(/\w\S*/g, (function (txt) { return txt[0].toUpperCase() + txt.substr(1).toLowerCase(); }));
        }
    };
    SignatureFormComponent.prototype.formatInstallationAddress = function () {
        var _this = this;
        var unit = '';
        var street = '';
        var suburb = '';
        var postcode = '';
        var stateAbbreviation = '';
        var unitType = '';
        this._installationAddress = '';
        if (this.assignmentDetail.installUnitType && 0 < this.assignmentDetail.installUnitType && this.assignmentDetail.installUnitType <= STREETTYPE.length) {
            unitType = UNITTYPE.filter(function (aUnitType) { return aUnitType.id == _this.assignmentDetail.installUnitType; })[0].name;
        }
        unit = unitType + ' ' + this.assignmentDetail.installUnitNumber;
        if (this.assignmentDetail.installStreetType && 0 < this.assignmentDetail.installStreetType && this.assignmentDetail.installStreetType <= STREETTYPE.length) {
            var streetType = STREETTYPE.filter(function (aStreetType) { return aStreetType.id == _this.assignmentDetail.installStreetType; })[0].name;
            street = this.assignmentDetail.installStreetNumber + ' ' + this.assignmentDetail.installStreetName + ' ' + streetType;
        }
        suburb = this.assignmentDetail.installSuburb;
        if (this.assignmentDetail.installState && this.assignmentDetail.installState > 0) {
            stateAbbreviation = AU_STATES_WITH_SHORTNAME.filter(function (aState) { return aState.id == _this.assignmentDetail.installState; })[0].abbreviation;
        }
        postcode = this.assignmentDetail.installPostcode;
        if (unit && unit.trim().length > 0) {
            this._installationAddress = unit + ', ';
        }
        if (street && street.trim().length > 0) {
            this._installationAddress += street + ', ';
        }
        if (suburb && suburb.trim().length > 0) {
            this._installationAddress += suburb + ', ';
        }
        if (stateAbbreviation && stateAbbreviation.trim().length > 0) {
            this._installationAddress += stateAbbreviation + ', ';
        }
        this._installationAddress = this._installationAddress += postcode;
        return this._installationAddress;
    };
    SignatureFormComponent.prototype.formatPanelDetail = function () {
        var _this = this;
        if (this.assignmentDetail.panels.length > 0) {
            if (this.assignmentDetail.panelsSameModel) {
                var thePanel = this.assignmentDetail.panels[0];
                this._panelDetail = thePanel.brand
                    + ' ' + thePanel.model;
                if (this.assignmentDetail.panels.length > 1) {
                    this._panelDetail = this.assignmentDetail.panels.length + ' * ' + this._panelDetail;
                }
                return this._panelDetail;
            }
            else {
                this._panelDetail = '';
                this.assignmentDetail.panels.forEach(function (aPanel, index) {
                    _this._panelDetail += aPanel.brand
                        + ' ' + aPanel.model;
                    if (_this.assignmentDetail.panels.length > 1 && _this.assignmentDetail.panels.length != index - 1) {
                        _this._panelDetail = _this._panelDetail + '\n';
                    }
                });
                return this._panelDetail;
            }
        }
        return '';
    };
    SignatureFormComponent.prototype.formatInverterDetail = function () {
        var _this = this;
        if (this.assignmentDetail.inverters.length > 0) {
            if (this.assignmentDetail.invertersSameModel) {
                var theInverter = this.assignmentDetail.inverters[0];
                this._inverterDetail = theInverter.inverterBrand
                    + ' ' + theInverter.inverterSeries
                    + ' ' + theInverter.inverterModel;
                if (this.assignmentDetail.inverters.length > 1) {
                    this._inverterDetail = this.assignmentDetail.inverters.length + ' * ' + this._inverterDetail;
                }
                return this._inverterDetail;
            }
            else {
                this._inverterDetail = '';
                this.assignmentDetail.inverters.forEach(function (aInverter, index) {
                    _this._inverterDetail += aInverter.inverterBrand
                        + ' ' + aInverter.inverterSeries
                        + ' ' + aInverter.inverterModel;
                    if (_this.assignmentDetail.inverters.length > 1 && _this.assignmentDetail.inverters.length != index - 1) {
                        _this._inverterDetail = _this._inverterDetail + '\n';
                    }
                });
                return this._inverterDetail;
            }
        }
        return '';
    };
    SignatureFormComponent.prototype.setupMandatoryDeclaration = function () {
        this.mandatoryDeclaration = [
            'I hereby assign the right to create Small-scale technology certificates (STCs) for the above System to ' + this.companyName + '.',
            "I understand that the System is eligible for <b>" + this.assignmentDetail.paymentRECs + "</b> STCs and,\n         in exchange for assigning my right to create these STCs, " + this.companyName + " will make payment to the person nominated in the payment details.",
            'I have read and understand the full terms and conditions on the website at <a href="www.green-bank.com.au/legal-notices">www.green-bank.com.au/legal-notices</a>',
            'I am aware that penalties apply for providing misleading information under the Renewable Energy (Electricity) Act 2000',
            "By signing this Assignment Form I acknowledge that the accredited installer and/or supervisor mentioned in this form, physically attented and installed/supervised\n        the installation of my system."
        ];
    };
    return SignatureFormComponent;
}());
export { SignatureFormComponent };
